import dayjs from "dayjs";

export const getTargetValueFromListOfTargets = (
  targets: any[],
  periodStartDate: any,
  periodEndDate: any
) => {
  const periodStart = periodStartDate ? dayjs(periodStartDate) : null;
  const periodEnd = periodEndDate ? dayjs(periodEndDate) : null;

  return targets.reduce((total: any, target: any) => {
    const { startDate, endDate, value } = target;

    const targetStart = dayjs(startDate);
    const targetEnd = dayjs(endDate);

    const maxStart = !periodStart
      ? targetStart
      : periodStart.isAfter(targetStart)
      ? periodStart
      : targetStart;
    const minEnd = !periodEnd
      ? targetEnd
      : periodEnd.isBefore(targetEnd)
      ? periodEnd
      : targetEnd;

    const timeNumerator = minEnd.valueOf() - maxStart.valueOf();
    const timeDenominator = targetEnd.valueOf() - targetStart.valueOf();

    const percentage = timeNumerator / timeDenominator;

    total += value * percentage;
    return total;
  }, 0);
};
