import {
  Divider,
  Group,
  Popover,
  Progress,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../../context/AppContext";
import { useMantineReactTable } from "mantine-react-table";
import {
  createResourcingRow,
  getDefaultSort,
  getPeriodsBetween,
} from "./utilities";
import { IconCheck } from "@tabler/icons-react";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import useFetchData from "../../../../hooks/useFetchData";
import { useDebouncedState } from "@mantine/hooks";

dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

dayjs.Ls.en.weekStart = 1;

export function useResourcingTable({
  dashboardFilterObjectList,
  updateDashboardFilter,
  globalFilteredData,
  comparisonGlobalFilteredData,
  comparisonFieldString,
}: any) {
  const theme = useMantineTheme();
  const { dataObject = {} } = useContext(AppContext);
  const { roleTypes = [], stages = [] } = dataObject;
  const [activeOnly, setActiveOnly] = useState<boolean>(true);
  const [includeLeave, setIncludeLeave] = useState<boolean>(true);
  const [roleTypesFilter, setRoleTypesFilter] = useState<string[]>([]);
  const [value, setValue] = useDebouncedState("", 300);
  const [period, setPeriod] = useState<"week" | "month" | "quarter">("week");
  const [status, setStatus] = useState<"Billable" | "Non Billable" | "">("");
  const [selectedStages, setSelectedStages] = useState<any[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().startOf("week").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    dayjs().add(6, "months").endOf("month").toDate()
  );

  const periodObjectList = useMemo(
    () => getPeriodsBetween(startDate, endDate, period),
    [startDate, endDate, period]
  );

  const [sorting, setSorting] = useState<any>(getDefaultSort(periodObjectList));

  useFetchData(["Role Types", "Stages"]);

  const loading = useLoadingStatus(["people", "roleTypes"]);

  const lowerCaseValue = useMemo(() => value.toLowerCase(), [value]);

  const modifiedData = useMemo(() => {
    const copy = structuredClone(globalFilteredData);

    const modified = copy.map((person: any) => {
      const roleTypes = person?.roleTypes?.items ?? [];
      person.roleTypesList = roleTypes.map(
        (roleType: any) => roleType?.roleType?.id
      );
      return person;
    });

    return modified;
  }, [globalFilteredData]);

  const data = useMemo(
    () =>
      modifiedData
        .filter((person: any) => {
          if (lowerCaseValue) {
            if (
              !`${person.firstName} ${person.lastName}`
                .toLowerCase()
                .includes(lowerCaseValue)
            )
              return false;
          }
          if (roleTypesFilter.length > 0) {
            if (
              !roleTypesFilter.some((roleType: any) =>
                person.roleTypesList.includes(roleType)
              )
            ) {
              return false;
            }
          }
          return activeOnly ? person.currentlyActive === "Yes" : true;
        })
        .map((person: any) => {
          return createResourcingRow({
            person,
            periodObjectList,
            status,
            includeLeave,
            selectedStages,
          });
        }),
    [
      modifiedData,
      periodObjectList,
      activeOnly,
      lowerCaseValue,
      roleTypesFilter,
      status,
      includeLeave,
      selectedStages,
    ]
  );

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ cell, column, renderedCellValue, row, table }: any) => {
          return (
            <Stack spacing={"xs"}>
              <Text fw={700} size="lg">
                {row.original.name}
              </Text>
              <Text size="xs" color="dimmed">
                {row.original.jobTitle}
              </Text>
            </Stack>
          );
        },
      },
      ...periodObjectList.map((obj, index) => {
        return {
          accessorKey: `${obj.period}.utilisation`,
          mantineTableBodyCellProps: {
            style: {
              width: "auto",
              minWidth: "auto",
              justifyContent: "center",
            },
          },
          mantineTableHeadCellProps: {
            style: {
              width: "auto",
              minWidth: "auto",
              justifyContent: "center",
            },
            className: "resourcing-table-header",
          },
          header: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Stack align="center" spacing={"xs"}>
                <Text color="dimmed">{obj.period}</Text>
                <Text>{dayjs(obj.start).format("DD/MM")}</Text>
              </Stack>
            </div>
          ),

          Cell: ({ cell, column, renderedCellValue, row, table }: any) => {
            // const [opened, { close, open }] = useDisclosure(false);
            const rounded = Number(renderedCellValue * 100).toFixed(0);
            const {
              detail = [],
              annualLeaveDetail = [],
              employeeSatisfaction,
              totalHours,
              totalHoursUtilised,
              leaveHours,
              billableHours,
              nonBillableHours,
            } = row.original[`${obj.period}`];

            const percentBillable = (billableHours * 100) / totalHoursUtilised;
            const percentNonBillable =
              (nonBillableHours * 100) / totalHoursUtilised;

            const leavePercentage = leaveHours / totalHours;

            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Popover
                  position="right"
                  withArrow
                  shadow="md"
                  // opened={opened}
                  disabled={
                    detail.length === 0 && annualLeaveDetail.length === 0
                  }
                  styles={{
                    dropdown: { maxHeight: 300, overflow: "scroll" },
                  }}
                >
                  <Popover.Target>
                    <div
                      style={{ position: "relative", cursor: "pointer" }}
                      // onClick={opened ? close : open}
                      // onMouseLeave={close}
                    >
                      {employeeSatisfaction}
                      {/* <div style={{ position: "absolute", bottom: 0, right: 60 }}> */}

                      <Stack
                        align="center"
                        spacing={"xs"}
                        style={{ width: 50 }}
                      >
                        <div>{rounded}%</div>
                        <div
                          style={{
                            height: 50,
                            width: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: `${
                              theme.colors[theme.primaryColor][3]
                            }30`,
                            borderRadius: "50%",
                          }}
                        >
                          <div
                            style={{
                              height: 50 * Math.min(renderedCellValue, 1),
                              width: 50 * Math.min(renderedCellValue, 1),
                              backgroundColor:
                                Number(rounded) > 100
                                  ? theme.colors["red"][7]
                                  : Number(rounded) === 100
                                  ? theme.colors["green"][7]
                                  : theme.colors[theme.primaryColor][7],
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {rounded === "100" && leaveHours === 0 && (
                              <IconCheck style={{ color: "white" }} />
                            )}
                          </div>
                          {leaveHours > 0 && (
                            <div
                              style={{
                                height: 50 * Math.min(leavePercentage, 1),
                                width: 50 * Math.min(leavePercentage, 1),
                                backgroundColor: theme.colors["blue"][7],
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                              }}
                            ></div>
                          )}
                        </div>
                      </Stack>
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown>
                    {annualLeaveDetail.length > 0 && (
                      <>
                        <Group>
                          <Text>Annual Leave: </Text>
                          <Text size={"xl"} fw={700}>
                            {leaveHours.toFixed(1)}
                          </Text>
                          /<Text color="dimmed">{totalHours.toFixed(1)}</Text>
                          <Text color="dimmed">
                            (
                            {Number((leaveHours * 100) / totalHours).toFixed(0)}
                            %)
                          </Text>
                        </Group>
                        <Table maw={500}>
                          <thead>
                            <tr>
                              {/* <th>Role Description</th> */}
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            {annualLeaveDetail.map(
                              (row: any, index: number) => {
                                const { startDate, endDate, hours } = row;

                                return (
                                  <tr key={index}>
                                    {/* <td>{description}</td> */}
                                    <td>{startDate}</td>
                                    <td>{endDate}</td>
                                    <td>{Number(hours).toFixed(0)}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </>
                    )}
                    {detail.length > 0 && annualLeaveDetail.length > 0 && (
                      <Divider my={"md"} />
                    )}
                    {detail.length > 0 && (
                      <>
                        <Group>
                          <Text>Utilization: </Text>
                          <Text size={"xl"} fw={700}>
                            {totalHoursUtilised.toFixed(1)}
                          </Text>
                          /<Text color="dimmed">{totalHours.toFixed(1)}</Text>
                          <Text color="dimmed">
                            (
                            {Number(
                              (totalHoursUtilised * 100) / totalHours
                            ).toFixed(0)}
                            %)
                          </Text>
                        </Group>
                        <Stack my="md">
                          <Group position={"apart"}>
                            <Text color="blue">
                              Billable ({billableHours.toFixed(1)})
                            </Text>
                            <Text color="red">
                              Non Billable ({nonBillableHours.toFixed(1)})
                            </Text>
                          </Group>
                          <Progress
                            size={"xl"}
                            sections={[
                              {
                                value: percentBillable,
                                color: "blue",
                                label: `${percentBillable.toFixed(0)}%`,
                              },
                              {
                                value: percentNonBillable,
                                color: "red",
                                label: `${percentNonBillable.toFixed(0)}%`,
                              },
                            ]}
                          />
                        </Stack>
                        <Table maw={500}>
                          <thead>
                            <tr>
                              {/* <th>Role Description</th> */}
                              <th>Project Name</th>
                              <th>Hours</th>
                              <th>% Allocated</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detail.map((row: any) => {
                              const {
                                description,
                                fte,
                                projectName,
                                status,
                                hours,
                              } = row;
                              if (projectName === "Annual Leave") {
                                return null;
                              }
                              return (
                                <tr key={description + projectName}>
                                  {/* <td>{description}</td> */}
                                  <td>{projectName}</td>
                                  <td>{hours.toFixed(1)}</td>
                                  <td>{Number(fte * 100).toFixed(0)}%</td>
                                  <td>{status}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    )}
                  </Popover.Dropdown>
                </Popover>
              </div>
            );
          },
        };
      }),
    ];
  }, [periodObjectList]);

  useEffect(() => {
    setSorting(getDefaultSort(periodObjectList));
  }, [periodObjectList]);

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data,
    enableColumnResizing: false,
    enableGrouping: true,
    enableStickyHeader: true,
    enableColumnDragging: false,
    enableStickyFooter: true,
    groupedColumnMode: false,
    enablePagination: false,
    enablePinning: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineTableBodyCellProps: { sx: { textWrap: "wrap" } },
    onSortingChange: (newSorting) => setSorting(newSorting),
    initialState: {
      density: "xs",
      //@ts-ignore
      expanded: false,
      grouping: [],
      //   pagination: { pageIndex: 0, pageSize: 100 },
      // sorting: [
      //   { id: `${periodObjectList[0].period}.utilisation`, desc: true },
      // ],
      columnPinning: { left: ["name"] },
    },
    state: {
      //@ts-ignore
      //   expanded: false,
      sorting,
      columnOrder: periodObjectList.map((obj, index) => {
        return `${obj.period}.utilisation`;
      }),
      columnVisibility: {
        //@ts-ignore
        // ...visibleFields[view],
        // engagementName: !(expanded === 0),
      },
    },
    mantineToolbarAlertBannerBadgeProps: { color: "blue", variant: "outline" },
    mantineTableContainerProps: { sx: {} },
    mantineTableProps: { style: { height: "100%" } },
    mantineTableHeadRowProps: {
      style: {
        boxShadow: `4px 0 ${theme.colorScheme === "dark" ? "8" : "4"}px ${
          theme.colors[theme.primaryColor][9]
        }`,
      },
    },
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
        maxHeight: "100%",
        height: "100%",
        display: "grid",
        gridTemplateRows: "1fr 0px ",
      },
    },
  });

  const stageOptions = useMemo(() => {
    return stages
      .filter((stage: any) => {
        return !["Closed Won", "Closed Lost"].includes(stage.name);
      })
      .map((stage: any) => {
        return { value: stage.id, label: stage.name };
      });
  }, [stages]);

  return {
    value,
    setValue,
    theme,
    roleTypes,
    roleTypesFilter,
    setRoleTypesFilter,
    period,
    setPeriod,
    status,
    setStatus,
    endDate,
    setEndDate,
    includeLeave,
    setIncludeLeave,
    startDate,
    loading,
    activeOnly,
    setActiveOnly,
    table,
    stageOptions,
    selectedStages,
    setSelectedStages,
  };
}
