import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const getRevenueStatusObjectFromProjectList = (projectList: any[]) => {
  const today = dayjs();
  // const TEMP: any = [];
  const projectObject = projectList.reduce(
    (object: any, project: any) => {
      const { startDate, endDate, revenueSold } = project;

      object.total += revenueSold;

      const isBetweenDates = today.isBetween(dayjs(startDate), dayjs(endDate));
      const isFinished = today.isAfter(endDate);

      let projectConsumed = isBetweenDates ? revenueSold : 0;
      let projectUnclaimed = isFinished ? revenueSold : 0;

      const statements = project?.statements?.items ?? [];

      for (const statement of statements) {
        const invoices = statement?.invoices?.items ?? [];
        for (const invoice of invoices) {
          if (invoice.paid) {
            object.paid += invoice.amount;
            if (isBetweenDates) {
              projectConsumed -= invoice.amount;
            }
            if (isFinished) {
              projectUnclaimed -= invoice.amount;
            }
          }
        }
      }

      // if (isFinished && projectUnclaimed > 10) {
      //   TEMP.push([
      //     project.name,
      //     revenueSold,
      //     projectUnclaimed,
      //     isBetweenDates,
      //     isFinished,
      //   ]);
      // }

      object.consumed += projectConsumed;
      object.unclaimed += projectUnclaimed;

      return object;
    },
    { total: 0, paid: 0, consumed: 0, unclaimed: 0 }
  );

  // console.table(TEMP);

  const { paid, consumed, total, unclaimed } = projectObject;

  const finalUnclaimed = Math.max(unclaimed, 0);
  const finalConsumed = Math.max(consumed, 0);

  const remainingValue = total - finalConsumed - paid - finalUnclaimed;

  const finalRemainingValue = Math.max(remainingValue, 0);

  const remainingPercentage = (finalRemainingValue / total) * 100;

  const paidPercentage = (paid / total) * 100;
  const consumedSurplusPercentage = (finalConsumed / total) * 100;
  const unclaimedPercentage = (finalUnclaimed / total) * 100;

  return {
    paid,
    paidPercentage,
    consumed,
    consumedSurplusPercentage,
    unclaimed,
    unclaimedPercentage,
    remainingPercentage,
    remainingValue,
    total,
  };
};
