import { Badge } from "@mantine/core";
import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";
import {
  formatCurrency,
  formatDateToYYYYMM,
} from "../../components/common/functions/formatFunctions";
import { Timesheet } from "../../API";

const invoiceSchema: ISchema = {
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },
  statementInvoicesId: {
    title: "Statement of Work",
    field: "statementInvoicesId",
    formOptions: {
      required: true,
      options: {
        dataSource: "statements",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.agreement };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },
  sow: {
    title: "Statement of Work Agreement",
    field: "sow",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row?.sow?.agreement;
    },
    excludeFromForm: true,
    formOptions: { required: false },
    // equivalentFields: { csats: "accountName" },
  },

  timesheetInvoicesId: {
    title: "Timesheet",
    field: "timesheetInvoicesId",
    isKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "timesheets",
        addBlank: true,
        accessorFunction: (dataSource: Timesheet[]) =>
          dataSource?.map((row) => {
            return { value: row.id, label: row.timesheetRef };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  timesheet: {
    title: "Timesheet",
    field: "timesheet",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row?.timesheet?.id;
    },
    excludeFromForm: true,
    formOptions: { required: false },
    // equivalentFields: { csats: "accountName" },
  },

  projectName: {
    title: "Project Name",
    field: "projectName",
    type: "belongsTo",
    accessorFunction: (row: any) => {
      return row?.sow?.project?.name;
    },
    excludeFromForm: true,
    formOptions: { required: false },
    equivalentFields: { projects: "name" },
  },

  invoiceNumber: {
    field: "invoiceNumber",
    title: "Invoice Number",
    formOptions: { required: false },
  },

  amount: {
    field: "amount",
    title: "Amount",
    type: "currency",
    formatFunction: (value: any) => formatCurrency(value, "GBP"),
    formOptions: { required: false },
  },

  invoiceDate: {
    field: "invoiceDate",
    title: "Invoice Date",
    type: "date",
    formOptions: { required: false },
  },

  invoiceMonth: {
    title: "Invoice Month",
    field: "invoiceMonth",
    type: "belongsTo",
    accessorFunction: (row: any) =>
      formatDateToYYYYMM(new Date(row.invoiceDate)),
    formOptions: { required: false },
    excludeFromForm: true,
  },

  reference: {
    field: "reference",
    title: "Reference",
    formOptions: { required: false },
  },
  billingAddress: {
    field: "billingAddress",
    title: "Billing Address",
    formOptions: { required: false },
  },
  billingEmail: {
    field: "billingEmail",
    title: "Billing Email",
    formOptions: { required: false },
  },
  VATRegNumber: {
    field: "VATRegNumber",
    title: "VAT Reg Number",
    formOptions: { required: false },
  },
  supplierNumber: {
    field: "supplierNumber",
    title: "Supplier Name",
    formOptions: { required: false },
  },
  supplierAddress: {
    field: "supplierAddress",
    title: "Supplier Address",
    formOptions: { required: false },
  },
  description: {
    field: "description",
    title: "Description",
    formOptions: { required: false },
  },
  unitPrice: {
    field: "unitPrice",
    title: "Unit Price",
    type: "currency",
    formOptions: { required: false },
  },
  quantity: {
    field: "quantity",
    title: "Quantity",
    type: "number",
    formOptions: { required: false },
  },
  taxAmount: {
    field: "taxAmount",
    title: "Tax Amount",
    type: "currency",
    formOptions: { required: false },
  },
  totalAmount: {
    field: "totalAmount",
    title: "Total Amount",
    type: "currency",
    formOptions: { required: false },
  },
  dueDate: {
    field: "dueDate",
    title: "Due Date",
    type: "date",
    formOptions: { required: false },
  },
  paymentTerms: {
    field: "paymentTerms",
    title: "Payment Terms",
    formOptions: { required: false },
  },
  bankName: {
    field: "bankName",
    title: "Bank Name",
    formOptions: { required: false },
  },
  accountName: {
    field: "accountName",
    title: "Account Name",
    formOptions: { required: false },
  },
  accountNumber: {
    field: "accountNumber",
    title: "Account Number",
    formOptions: { required: false },
  },
  sortCode: {
    field: "sortCode",
    title: "Sort Code",
    formOptions: { required: false },
  },
  routingNumber: {
    field: "routingNumber",
    title: "Routing Number",
    formOptions: { required: false },
  },
  paid: {
    field: "paid",
    title: "Paid",
    type: "boolean",
    formatFunction: (value: boolean) =>
      value ? (
        <Badge color="green">Paid</Badge>
      ) : (
        <Badge color="gray">Not Paid</Badge>
      ),
    formOptions: { required: false },
  },

  paidStatus: {
    field: "paidStatus",
    title: "Paid Status",
    accessorFunction: (row: any) => (row.paid ? "Paid" : "Not Paid"),
    formOptions: { required: false },
    excludeFromForm: true,
  },

  ...common,
};

export default invoiceSchema;
