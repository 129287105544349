import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import AppContext from "../context/AppContext";
// import { DataSourceStrings } from "../types/systemPreferencesTypes";
import fetchModuleRecords from "../components/common/functions/fetchModuleRecords";
import {
  // listRisks,
  listStatements,
  // listPeople,
  listDeliverables,
  listChanges,
  listCsats,
  listWeeklyUpdates,
  listAccounts,
  listTenants,
  listMRRS,
  listCosts,
  listPeriods,
  listPLS,
  listVerticals,
  listProgrammes,
  listIntegrations,
  listDashboards,
  listStages,
  listEmployeeSatisfactions,
  listInvoices,
  listPipelines,
  listProjectAllocations,
  // listProjectRoles,
  listRegions,
  listTasks,
  listTimesheets,
  listAnnualLeaves,
  listTargets,
} from "../graphql-custom/generated";
import { fetchCustomData } from "../graphql/queries";
import { listCustomDatasets, listUserTypes } from "../graphql/queries";
import {
  listPeople,
  listRisks,
  listProjects,
  listRoleTypes,
  listProjectRoles,
} from "../graphql-custom/updatedQueries";

import { dataKeyLookup } from "../context/references";

const moduleToDataKeyObject = {
  tenants: { listTenants },
  accounts: { listAccounts },
  risks: { listRisks },
  people: { listPeople },
  projects: { listProjects },
  statements: { listStatements },
  deliverables: { listDeliverables },
  changes: { listChanges },
  csats: { listCsats },
  weeklyUpdates: { listWeeklyUpdates },
  mrrs: { listMRRS },
  costs: { listCosts },
  pls: { listPLS },
  verticals: { listVerticals },
  programmes: { listProgrammes },
  integrations: { listIntegrations },
  dashboards: { listDashboards },
  stages: { listStages },

  periods: { listPeriods },

  employeeSatisfaction: { listEmployeeSatisfactions },
  invoices: { listInvoices },
  pipelines: { listPipelines },
  projectAllocations: { listProjectAllocations },
  projectRoles: { listProjectRoles },
  regions: { listRegions },
  roleTypes: { listRoleTypes },
  tasks: { listTasks },
  timesheets: { listTimesheets },
  userTypes: { listUserTypes },
  customDatasets: { listCustomDatasets },
  annualLeaves: { listAnnualLeaves },
  targets: { listTargets },
};

const overridePeriods = ["Live"];

export default function useFetchData(
  moduleNames: string[],
  periods: string[] = ["Live"],
  inputModulePeriods: string[] = []
) {
  const { setDataObject, loading, currentTenant, queuedModules } =
    useContext(AppContext);

  const modulePeriods: string[] = useMemo(() => {
    const modulePeriods: string[] = [];

    for (let j = 0; j < overridePeriods.length; j++) {
      for (let i = 0; i < moduleNames.length; i++) {
        modulePeriods.push(`${moduleNames[i]}#${overridePeriods[j]}`);
      }
    }
    return inputModulePeriods.concat(modulePeriods);
    // return modulePeriods;
  }, [moduleNames, overridePeriods]);

  const getData = useCallback(
    async (filteredModulePeriodNames: string[]) => {
      const fetchDataInputList = filteredModulePeriodNames.map(
        (modulePeriodName: string) => {
          const [moduleName, period] = modulePeriodName.split("#");

          return {
            active: true,
            dataSource: "database",
            dataKey: dataKeyLookup[moduleName] ?? moduleName,
            period,
          };
        }
      );

      for (var i = 0; i < fetchDataInputList.length; i++) {
        const moduleObject = fetchDataInputList[i];

        new Promise(async (resolve, reject) => {
          const additionalData = await fetchModuleRecords({
            listQuery: moduleToDataKeyObject[moduleObject.dataKey] ?? {
              fetchCustomData,
            },
            currentTenant,
            period: moduleObject.period,
            dataKey: moduleObject.dataKey,
          });
          resolve(additionalData);
        }).then((additionalData: any) => {
          setDataObject((dataObject: any) => {
            let newData = [];

            if (dataObject[moduleObject.dataKey]) {
              newData = [
                ...additionalData,
                ...dataObject[moduleObject.dataKey],
              ];
            } else {
              newData = additionalData;
            }

            return {
              ...dataObject,
              [moduleObject.dataKey]: newData,
            };
          });
        });
      }
    },
    [setDataObject, currentTenant]
  );

  useEffect(() => {
    if (loading || !currentTenant) return;
    const filteredModuleNames = modulePeriods.filter(
      //@ts-ignore
      (moduleName: string) => !queuedModules.current.includes(moduleName) //&&
    );

    queuedModules.current = [...filteredModuleNames, ...queuedModules.current];

    if (filteredModuleNames.length > 0) {
      getData(filteredModuleNames);
    }
  }, [loading, currentTenant, modulePeriods]);
}
