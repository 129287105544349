/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSystemPreference = /* GraphQL */ `mutation CreateSystemPreference(
  $input: CreateSystemPreferenceInput!
  $condition: ModelSystemPreferenceConditionInput
) {
  createSystemPreference(input: $input, condition: $condition) {
    ownerEmail
    tenantAccessString
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemPreferenceMutationVariables,
  APITypes.CreateSystemPreferenceMutation
>;
export const updateSystemPreference = /* GraphQL */ `mutation UpdateSystemPreference(
  $input: UpdateSystemPreferenceInput!
  $condition: ModelSystemPreferenceConditionInput
) {
  updateSystemPreference(input: $input, condition: $condition) {
    ownerEmail
    tenantAccessString
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemPreferenceMutationVariables,
  APITypes.UpdateSystemPreferenceMutation
>;
export const deleteSystemPreference = /* GraphQL */ `mutation DeleteSystemPreference(
  $input: DeleteSystemPreferenceInput!
  $condition: ModelSystemPreferenceConditionInput
) {
  deleteSystemPreference(input: $input, condition: $condition) {
    ownerEmail
    tenantAccessString
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemPreferenceMutationVariables,
  APITypes.DeleteSystemPreferenceMutation
>;
export const createUserType = /* GraphQL */ `mutation CreateUserType(
  $input: CreateUserTypeInput!
  $condition: ModelUserTypeConditionInput
) {
  createUserType(input: $input, condition: $condition) {
    id
    name
    tenantAccessString
    permissionsObject
    audit
    period
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserTypeMutationVariables,
  APITypes.CreateUserTypeMutation
>;
export const updateUserType = /* GraphQL */ `mutation UpdateUserType(
  $input: UpdateUserTypeInput!
  $condition: ModelUserTypeConditionInput
) {
  updateUserType(input: $input, condition: $condition) {
    id
    name
    tenantAccessString
    permissionsObject
    audit
    period
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserTypeMutationVariables,
  APITypes.UpdateUserTypeMutation
>;
export const deleteUserType = /* GraphQL */ `mutation DeleteUserType(
  $input: DeleteUserTypeInput!
  $condition: ModelUserTypeConditionInput
) {
  deleteUserType(input: $input, condition: $condition) {
    id
    name
    tenantAccessString
    permissionsObject
    audit
    period
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserTypeMutationVariables,
  APITypes.DeleteUserTypeMutation
>;
export const createUserPreference = /* GraphQL */ `mutation CreateUserPreference(
  $input: CreateUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  createUserPreference(input: $input, condition: $condition) {
    ownerEmail
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPreferenceMutationVariables,
  APITypes.CreateUserPreferenceMutation
>;
export const updateUserPreference = /* GraphQL */ `mutation UpdateUserPreference(
  $input: UpdateUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  updateUserPreference(input: $input, condition: $condition) {
    ownerEmail
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPreferenceMutationVariables,
  APITypes.UpdateUserPreferenceMutation
>;
export const deleteUserPreference = /* GraphQL */ `mutation DeleteUserPreference(
  $input: DeleteUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  deleteUserPreference(input: $input, condition: $condition) {
    ownerEmail
    preferenceObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPreferenceMutationVariables,
  APITypes.DeleteUserPreferenceMutation
>;
export const createIntegration = /* GraphQL */ `mutation CreateIntegration(
  $input: CreateIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  createIntegration(input: $input, condition: $condition) {
    id
    module
    name
    active
    status
    dataLastUpdated
    system
    tenantAccessString
    tenantUserAccessString
    dataObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationMutationVariables,
  APITypes.CreateIntegrationMutation
>;
export const updateIntegration = /* GraphQL */ `mutation UpdateIntegration(
  $input: UpdateIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  updateIntegration(input: $input, condition: $condition) {
    id
    module
    name
    active
    status
    dataLastUpdated
    system
    tenantAccessString
    tenantUserAccessString
    dataObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationMutationVariables,
  APITypes.UpdateIntegrationMutation
>;
export const deleteIntegration = /* GraphQL */ `mutation DeleteIntegration(
  $input: DeleteIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  deleteIntegration(input: $input, condition: $condition) {
    id
    module
    name
    active
    status
    dataLastUpdated
    system
    tenantAccessString
    tenantUserAccessString
    dataObject
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationMutationVariables,
  APITypes.DeleteIntegrationMutation
>;
export const createDashboard = /* GraphQL */ `mutation CreateDashboard(
  $input: CreateDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  createDashboard(input: $input, condition: $condition) {
    id
    name
    order
    tenantAccessString
    userTypeAccessStrings
    configuration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDashboardMutationVariables,
  APITypes.CreateDashboardMutation
>;
export const updateDashboard = /* GraphQL */ `mutation UpdateDashboard(
  $input: UpdateDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  updateDashboard(input: $input, condition: $condition) {
    id
    name
    order
    tenantAccessString
    userTypeAccessStrings
    configuration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDashboardMutationVariables,
  APITypes.UpdateDashboardMutation
>;
export const deleteDashboard = /* GraphQL */ `mutation DeleteDashboard(
  $input: DeleteDashboardInput!
  $condition: ModelDashboardConditionInput
) {
  deleteDashboard(input: $input, condition: $condition) {
    id
    name
    order
    tenantAccessString
    userTypeAccessStrings
    configuration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardMutationVariables,
  APITypes.DeleteDashboardMutation
>;
export const createTenant = /* GraphQL */ `mutation CreateTenant(
  $input: CreateTenantInput!
  $condition: ModelTenantConditionInput
) {
  createTenant(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    active
    stages {
      nextToken
      __typename
    }
    verticals {
      nextToken
      __typename
    }
    accounts {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    people {
      nextToken
      __typename
    }
    customDatasets {
      nextToken
      __typename
    }
    periods {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantMutationVariables,
  APITypes.CreateTenantMutation
>;
export const updateTenant = /* GraphQL */ `mutation UpdateTenant(
  $input: UpdateTenantInput!
  $condition: ModelTenantConditionInput
) {
  updateTenant(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    active
    stages {
      nextToken
      __typename
    }
    verticals {
      nextToken
      __typename
    }
    accounts {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    people {
      nextToken
      __typename
    }
    customDatasets {
      nextToken
      __typename
    }
    periods {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenantMutationVariables,
  APITypes.UpdateTenantMutation
>;
export const deleteTenant = /* GraphQL */ `mutation DeleteTenant(
  $input: DeleteTenantInput!
  $condition: ModelTenantConditionInput
) {
  deleteTenant(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    active
    stages {
      nextToken
      __typename
    }
    verticals {
      nextToken
      __typename
    }
    accounts {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    people {
      nextToken
      __typename
    }
    customDatasets {
      nextToken
      __typename
    }
    periods {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenantMutationVariables,
  APITypes.DeleteTenantMutation
>;
export const createPeriod = /* GraphQL */ `mutation CreatePeriod(
  $input: CreatePeriodInput!
  $condition: ModelPeriodConditionInput
) {
  createPeriod(input: $input, condition: $condition) {
    id
    tenantAccessString
    name
    period
    audit
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    tenantPeriodsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePeriodMutationVariables,
  APITypes.CreatePeriodMutation
>;
export const updatePeriod = /* GraphQL */ `mutation UpdatePeriod(
  $input: UpdatePeriodInput!
  $condition: ModelPeriodConditionInput
) {
  updatePeriod(input: $input, condition: $condition) {
    id
    tenantAccessString
    name
    period
    audit
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    tenantPeriodsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePeriodMutationVariables,
  APITypes.UpdatePeriodMutation
>;
export const deletePeriod = /* GraphQL */ `mutation DeletePeriod(
  $input: DeletePeriodInput!
  $condition: ModelPeriodConditionInput
) {
  deletePeriod(input: $input, condition: $condition) {
    id
    tenantAccessString
    name
    period
    audit
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    tenantPeriodsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePeriodMutationVariables,
  APITypes.DeletePeriodMutation
>;
export const createVertical = /* GraphQL */ `mutation CreateVertical(
  $input: CreateVerticalInput!
  $condition: ModelVerticalConditionInput
) {
  createVertical(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    targets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantVerticalsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVerticalMutationVariables,
  APITypes.CreateVerticalMutation
>;
export const updateVertical = /* GraphQL */ `mutation UpdateVertical(
  $input: UpdateVerticalInput!
  $condition: ModelVerticalConditionInput
) {
  updateVertical(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    targets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantVerticalsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVerticalMutationVariables,
  APITypes.UpdateVerticalMutation
>;
export const deleteVertical = /* GraphQL */ `mutation DeleteVertical(
  $input: DeleteVerticalInput!
  $condition: ModelVerticalConditionInput
) {
  deleteVertical(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    targets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantVerticalsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVerticalMutationVariables,
  APITypes.DeleteVerticalMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantAccountsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantAccountsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    programmes {
      nextToken
      __typename
    }
    pipelines {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantAccountsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createProgramme = /* GraphQL */ `mutation CreateProgramme(
  $input: CreateProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  createProgramme(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    projects {
      nextToken
      __typename
    }
    csats {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    weeklyUpdates {
      nextToken
      __typename
    }
    mrrs {
      nextToken
      __typename
    }
    costs {
      nextToken
      __typename
    }
    pls {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalProgrammesId
    accountProgrammesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProgrammeMutationVariables,
  APITypes.CreateProgrammeMutation
>;
export const updateProgramme = /* GraphQL */ `mutation UpdateProgramme(
  $input: UpdateProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  updateProgramme(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    projects {
      nextToken
      __typename
    }
    csats {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    weeklyUpdates {
      nextToken
      __typename
    }
    mrrs {
      nextToken
      __typename
    }
    costs {
      nextToken
      __typename
    }
    pls {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalProgrammesId
    accountProgrammesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProgrammeMutationVariables,
  APITypes.UpdateProgrammeMutation
>;
export const deleteProgramme = /* GraphQL */ `mutation DeleteProgramme(
  $input: DeleteProgrammeInput!
  $condition: ModelProgrammeConditionInput
) {
  deleteProgramme(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    projects {
      nextToken
      __typename
    }
    csats {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    weeklyUpdates {
      nextToken
      __typename
    }
    mrrs {
      nextToken
      __typename
    }
    costs {
      nextToken
      __typename
    }
    pls {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalProgrammesId
    accountProgrammesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProgrammeMutationVariables,
  APITypes.DeleteProgrammeMutation
>;
export const createTarget = /* GraphQL */ `mutation CreateTarget(
  $input: CreateTargetInput!
  $condition: ModelTargetConditionInput
) {
  createTarget(input: $input, condition: $condition) {
    id
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tenantAccessString
    verticalAccessString
    programmeAccessString
    projectAccessString
    startDate
    endDate
    module
    field
    value
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    verticalTargetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTargetMutationVariables,
  APITypes.CreateTargetMutation
>;
export const updateTarget = /* GraphQL */ `mutation UpdateTarget(
  $input: UpdateTargetInput!
  $condition: ModelTargetConditionInput
) {
  updateTarget(input: $input, condition: $condition) {
    id
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tenantAccessString
    verticalAccessString
    programmeAccessString
    projectAccessString
    startDate
    endDate
    module
    field
    value
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    verticalTargetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTargetMutationVariables,
  APITypes.UpdateTargetMutation
>;
export const deleteTarget = /* GraphQL */ `mutation DeleteTarget(
  $input: DeleteTargetInput!
  $condition: ModelTargetConditionInput
) {
  deleteTarget(input: $input, condition: $condition) {
    id
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    tenantAccessString
    verticalAccessString
    programmeAccessString
    projectAccessString
    startDate
    endDate
    module
    field
    value
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    verticalTargetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTargetMutationVariables,
  APITypes.DeleteTargetMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    deliveryManagers {
      nextToken
      __typename
    }
    projectManagers {
      nextToken
      __typename
    }
    name
    tcv
    margin
    workstream
    currency
    budget
    spend
    country
    status
    startDate
    endDate
    revenueSold
    costSold
    revenueActual
    costActual
    consumed
    region {
      id
      continent
      subregion
      name
      createdAt
      updatedAt
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    statements {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    programmeProjectsId
    regionProjectsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    deliveryManagers {
      nextToken
      __typename
    }
    projectManagers {
      nextToken
      __typename
    }
    name
    tcv
    margin
    workstream
    currency
    budget
    spend
    country
    status
    startDate
    endDate
    revenueSold
    costSold
    revenueActual
    costActual
    consumed
    region {
      id
      continent
      subregion
      name
      createdAt
      updatedAt
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    statements {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    programmeProjectsId
    regionProjectsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    deliveryManagers {
      nextToken
      __typename
    }
    projectManagers {
      nextToken
      __typename
    }
    name
    tcv
    margin
    workstream
    currency
    budget
    spend
    country
    status
    startDate
    endDate
    revenueSold
    costSold
    revenueActual
    costActual
    consumed
    region {
      id
      continent
      subregion
      name
      createdAt
      updatedAt
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    statements {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    programmeProjectsId
    regionProjectsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createRisk = /* GraphQL */ `mutation CreateRisk(
  $input: CreateRiskInput!
  $condition: ModelRiskConditionInput
) {
  createRisk(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    description
    actionOwner
    status
    impactDate
    cause
    effect
    impactDescription
    impactType
    riskResponse
    actionMitigation
    targetResolutionDate
    probability
    impact
    estimatedImpactCost
    escalatedToPortfolio
    detailedUpdate
    dateRaised
    dateOfNextReview
    riskOwners {
      nextToken
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    verticalRisksId
    accountRisksId
    programmeRisksId
    projectRisksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRiskMutationVariables,
  APITypes.CreateRiskMutation
>;
export const updateRisk = /* GraphQL */ `mutation UpdateRisk(
  $input: UpdateRiskInput!
  $condition: ModelRiskConditionInput
) {
  updateRisk(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    description
    actionOwner
    status
    impactDate
    cause
    effect
    impactDescription
    impactType
    riskResponse
    actionMitigation
    targetResolutionDate
    probability
    impact
    estimatedImpactCost
    escalatedToPortfolio
    detailedUpdate
    dateRaised
    dateOfNextReview
    riskOwners {
      nextToken
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    verticalRisksId
    accountRisksId
    programmeRisksId
    projectRisksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRiskMutationVariables,
  APITypes.UpdateRiskMutation
>;
export const deleteRisk = /* GraphQL */ `mutation DeleteRisk(
  $input: DeleteRiskInput!
  $condition: ModelRiskConditionInput
) {
  deleteRisk(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    description
    actionOwner
    status
    impactDate
    cause
    effect
    impactDescription
    impactType
    riskResponse
    actionMitigation
    targetResolutionDate
    probability
    impact
    estimatedImpactCost
    escalatedToPortfolio
    detailedUpdate
    dateRaised
    dateOfNextReview
    riskOwners {
      nextToken
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    verticalRisksId
    accountRisksId
    programmeRisksId
    projectRisksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRiskMutationVariables,
  APITypes.DeleteRiskMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask(
  $input: CreateTaskInput!
  $condition: ModelTaskConditionInput
) {
  createTask(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    complete
    category
    type
    status
    startDate
    endDate
    completedDate
    assignees {
      nextToken
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    projectTasksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask(
  $input: UpdateTaskInput!
  $condition: ModelTaskConditionInput
) {
  updateTask(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    complete
    category
    type
    status
    startDate
    endDate
    completedDate
    assignees {
      nextToken
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    projectTasksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask(
  $input: DeleteTaskInput!
  $condition: ModelTaskConditionInput
) {
  deleteTask(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    complete
    category
    type
    status
    startDate
    endDate
    completedDate
    assignees {
      nextToken
      __typename
    }
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    createdAt
    updatedAt
    projectTasksId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createCsat = /* GraphQL */ `mutation CreateCsat(
  $input: CreateCsatInput!
  $condition: ModelCsatConditionInput
) {
  createCsat(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    score
    comments
    date
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    createdAt
    updatedAt
    programmeCsatsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCsatMutationVariables,
  APITypes.CreateCsatMutation
>;
export const updateCsat = /* GraphQL */ `mutation UpdateCsat(
  $input: UpdateCsatInput!
  $condition: ModelCsatConditionInput
) {
  updateCsat(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    score
    comments
    date
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    createdAt
    updatedAt
    programmeCsatsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCsatMutationVariables,
  APITypes.UpdateCsatMutation
>;
export const deleteCsat = /* GraphQL */ `mutation DeleteCsat(
  $input: DeleteCsatInput!
  $condition: ModelCsatConditionInput
) {
  deleteCsat(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    score
    comments
    date
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    createdAt
    updatedAt
    programmeCsatsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCsatMutationVariables,
  APITypes.DeleteCsatMutation
>;
export const createStage = /* GraphQL */ `mutation CreateStage(
  $input: CreateStageInput!
  $condition: ModelStageConditionInput
) {
  createStage(input: $input, condition: $condition) {
    id
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    order
    probability
    name
    pipelines {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantStagesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStageMutationVariables,
  APITypes.CreateStageMutation
>;
export const updateStage = /* GraphQL */ `mutation UpdateStage(
  $input: UpdateStageInput!
  $condition: ModelStageConditionInput
) {
  updateStage(input: $input, condition: $condition) {
    id
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    order
    probability
    name
    pipelines {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantStagesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStageMutationVariables,
  APITypes.UpdateStageMutation
>;
export const deleteStage = /* GraphQL */ `mutation DeleteStage(
  $input: DeleteStageInput!
  $condition: ModelStageConditionInput
) {
  deleteStage(input: $input, condition: $condition) {
    id
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    order
    probability
    name
    pipelines {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantStagesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStageMutationVariables,
  APITypes.DeleteStageMutation
>;
export const createPipeline = /* GraphQL */ `mutation CreatePipeline(
  $input: CreatePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  createPipeline(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    amount
    closeDate
    closedNotes
    closingDate
    currency
    dealName
    description
    grossMarginLength
    grossMarginPerMonth
    incomeType
    intro
    managingPartner
    numberOfDeliveryDays
    opportunityLead
    pipeline
    serviceGoLiveDate
    sourceOfferingOpportunity
    totalGrossProfitValue
    totalRevenueValue
    typeOfSale
    stage {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      probability
      name
      createdAt
      updatedAt
      tenantStagesId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    sowRecords {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalPipelinesId
    accountPipelinesId
    stagePipelinesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePipelineMutationVariables,
  APITypes.CreatePipelineMutation
>;
export const updatePipeline = /* GraphQL */ `mutation UpdatePipeline(
  $input: UpdatePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  updatePipeline(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    amount
    closeDate
    closedNotes
    closingDate
    currency
    dealName
    description
    grossMarginLength
    grossMarginPerMonth
    incomeType
    intro
    managingPartner
    numberOfDeliveryDays
    opportunityLead
    pipeline
    serviceGoLiveDate
    sourceOfferingOpportunity
    totalGrossProfitValue
    totalRevenueValue
    typeOfSale
    stage {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      probability
      name
      createdAt
      updatedAt
      tenantStagesId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    sowRecords {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalPipelinesId
    accountPipelinesId
    stagePipelinesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePipelineMutationVariables,
  APITypes.UpdatePipelineMutation
>;
export const deletePipeline = /* GraphQL */ `mutation DeletePipeline(
  $input: DeletePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  deletePipeline(input: $input, condition: $condition) {
    id
    tenantAccessString
    accountAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    amount
    closeDate
    closedNotes
    closingDate
    currency
    dealName
    description
    grossMarginLength
    grossMarginPerMonth
    incomeType
    intro
    managingPartner
    numberOfDeliveryDays
    opportunityLead
    pipeline
    serviceGoLiveDate
    sourceOfferingOpportunity
    totalGrossProfitValue
    totalRevenueValue
    typeOfSale
    stage {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      order
      probability
      name
      createdAt
      updatedAt
      tenantStagesId
      owner
      __typename
    }
    vertical {
      id
      tenantAccessString
      verticalAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantVerticalsId
      owner
      __typename
    }
    account {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantAccountsId
      owner
      __typename
    }
    sowRecords {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    verticalPipelinesId
    accountPipelinesId
    stagePipelinesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePipelineMutationVariables,
  APITypes.DeletePipelineMutation
>;
export const createStatement = /* GraphQL */ `mutation CreateStatement(
  $input: CreateStatementInput!
  $condition: ModelStatementConditionInput
) {
  createStatement(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    agreement
    description
    date
    contractType
    days
    durationDays
    location
    valueCredits
    valueUsd
    consumed
    workPackages
    paid
    stage
    completedDate
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    deliverables {
      nextToken
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    changes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectStatementsId
    pipelineSowRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStatementMutationVariables,
  APITypes.CreateStatementMutation
>;
export const updateStatement = /* GraphQL */ `mutation UpdateStatement(
  $input: UpdateStatementInput!
  $condition: ModelStatementConditionInput
) {
  updateStatement(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    agreement
    description
    date
    contractType
    days
    durationDays
    location
    valueCredits
    valueUsd
    consumed
    workPackages
    paid
    stage
    completedDate
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    deliverables {
      nextToken
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    changes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectStatementsId
    pipelineSowRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStatementMutationVariables,
  APITypes.UpdateStatementMutation
>;
export const deleteStatement = /* GraphQL */ `mutation DeleteStatement(
  $input: DeleteStatementInput!
  $condition: ModelStatementConditionInput
) {
  deleteStatement(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    agreement
    description
    date
    contractType
    days
    durationDays
    location
    valueCredits
    valueUsd
    consumed
    workPackages
    paid
    stage
    completedDate
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    deliverables {
      nextToken
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    changes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectStatementsId
    pipelineSowRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStatementMutationVariables,
  APITypes.DeleteStatementMutation
>;
export const createDeliverable = /* GraphQL */ `mutation CreateDeliverable(
  $input: CreateDeliverableInput!
  $condition: ModelDeliverableConditionInput
) {
  createDeliverable(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    artifact
    deliverableOwner
    progress
    startDate
    dueDate
    eta
    actualDate
    comments
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementDeliverablesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeliverableMutationVariables,
  APITypes.CreateDeliverableMutation
>;
export const updateDeliverable = /* GraphQL */ `mutation UpdateDeliverable(
  $input: UpdateDeliverableInput!
  $condition: ModelDeliverableConditionInput
) {
  updateDeliverable(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    artifact
    deliverableOwner
    progress
    startDate
    dueDate
    eta
    actualDate
    comments
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementDeliverablesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeliverableMutationVariables,
  APITypes.UpdateDeliverableMutation
>;
export const deleteDeliverable = /* GraphQL */ `mutation DeleteDeliverable(
  $input: DeleteDeliverableInput!
  $condition: ModelDeliverableConditionInput
) {
  deleteDeliverable(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    artifact
    deliverableOwner
    progress
    startDate
    dueDate
    eta
    actualDate
    comments
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementDeliverablesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeliverableMutationVariables,
  APITypes.DeleteDeliverableMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    invoiceNumber
    amount
    invoiceDate
    reference
    billingAddress
    billingEmail
    VATRegNumber
    supplierNumber
    supplierAddress
    description
    unitPrice
    quantity
    taxAmount
    totalAmount
    dueDate
    paymentTerms
    bankName
    accountName
    accountNumber
    sortCode
    routingNumber
    paid
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    timesheet {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      timesheetRef
      unit
      unitAmount
      unitRate
      unitCost
      startDate
      endDate
      timeSheetData
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementInvoicesId
    timesheetInvoicesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    invoiceNumber
    amount
    invoiceDate
    reference
    billingAddress
    billingEmail
    VATRegNumber
    supplierNumber
    supplierAddress
    description
    unitPrice
    quantity
    taxAmount
    totalAmount
    dueDate
    paymentTerms
    bankName
    accountName
    accountNumber
    sortCode
    routingNumber
    paid
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    timesheet {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      timesheetRef
      unit
      unitAmount
      unitRate
      unitCost
      startDate
      endDate
      timeSheetData
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementInvoicesId
    timesheetInvoicesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    invoiceNumber
    amount
    invoiceDate
    reference
    billingAddress
    billingEmail
    VATRegNumber
    supplierNumber
    supplierAddress
    description
    unitPrice
    quantity
    taxAmount
    totalAmount
    dueDate
    paymentTerms
    bankName
    accountName
    accountNumber
    sortCode
    routingNumber
    paid
    sow {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    timesheet {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      timesheetRef
      unit
      unitAmount
      unitRate
      unitCost
      startDate
      endDate
      timeSheetData
      createdAt
      updatedAt
      personTimesheetsId
      projectRoleTimesheetsId
      owner
      __typename
    }
    createdAt
    updatedAt
    statementInvoicesId
    timesheetInvoicesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createRegion = /* GraphQL */ `mutation CreateRegion(
  $input: CreateRegionInput!
  $condition: ModelRegionConditionInput
) {
  createRegion(input: $input, condition: $condition) {
    id
    continent
    subregion
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegionMutationVariables,
  APITypes.CreateRegionMutation
>;
export const updateRegion = /* GraphQL */ `mutation UpdateRegion(
  $input: UpdateRegionInput!
  $condition: ModelRegionConditionInput
) {
  updateRegion(input: $input, condition: $condition) {
    id
    continent
    subregion
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegionMutationVariables,
  APITypes.UpdateRegionMutation
>;
export const deleteRegion = /* GraphQL */ `mutation DeleteRegion(
  $input: DeleteRegionInput!
  $condition: ModelRegionConditionInput
) {
  deleteRegion(input: $input, condition: $condition) {
    id
    continent
    subregion
    name
    projects {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRegionMutationVariables,
  APITypes.DeleteRegionMutation
>;
export const createRoleType = /* GraphQL */ `mutation CreateRoleType(
  $input: CreateRoleTypeInput!
  $condition: ModelRoleTypeConditionInput
) {
  createRoleType(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    people {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantRoleTypesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleTypeMutationVariables,
  APITypes.CreateRoleTypeMutation
>;
export const updateRoleType = /* GraphQL */ `mutation UpdateRoleType(
  $input: UpdateRoleTypeInput!
  $condition: ModelRoleTypeConditionInput
) {
  updateRoleType(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    people {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantRoleTypesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleTypeMutationVariables,
  APITypes.UpdateRoleTypeMutation
>;
export const deleteRoleType = /* GraphQL */ `mutation DeleteRoleType(
  $input: DeleteRoleTypeInput!
  $condition: ModelRoleTypeConditionInput
) {
  deleteRoleType(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    people {
      nextToken
      __typename
    }
    projectRoles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantRoleTypesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleTypeMutationVariables,
  APITypes.DeleteRoleTypeMutation
>;
export const createPerson = /* GraphQL */ `mutation CreatePerson(
  $input: CreatePersonInput!
  $condition: ModelPersonConditionInput
) {
  createPerson(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    firstName
    lastName
    email
    position
    location
    hoursPerWeek
    currentlyActive
    skills
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    employeeSatisfactionRecords {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    annualLeave {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    projectsDeliveryManaged {
      nextToken
      __typename
    }
    projectsProjectManaged {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantPeopleId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePersonMutationVariables,
  APITypes.CreatePersonMutation
>;
export const updatePerson = /* GraphQL */ `mutation UpdatePerson(
  $input: UpdatePersonInput!
  $condition: ModelPersonConditionInput
) {
  updatePerson(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    firstName
    lastName
    email
    position
    location
    hoursPerWeek
    currentlyActive
    skills
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    employeeSatisfactionRecords {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    annualLeave {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    projectsDeliveryManaged {
      nextToken
      __typename
    }
    projectsProjectManaged {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantPeopleId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePersonMutationVariables,
  APITypes.UpdatePersonMutation
>;
export const deletePerson = /* GraphQL */ `mutation DeletePerson(
  $input: DeletePersonInput!
  $condition: ModelPersonConditionInput
) {
  deletePerson(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    firstName
    lastName
    email
    position
    location
    hoursPerWeek
    currentlyActive
    skills
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    employeeSatisfactionRecords {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    annualLeave {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    risks {
      nextToken
      __typename
    }
    tasks {
      nextToken
      __typename
    }
    projectsDeliveryManaged {
      nextToken
      __typename
    }
    projectsProjectManaged {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    tenantPeopleId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePersonMutationVariables,
  APITypes.DeletePersonMutation
>;
export const createAnnualLeave = /* GraphQL */ `mutation CreateAnnualLeave(
  $input: CreateAnnualLeaveInput!
  $condition: ModelAnnualLeaveConditionInput
) {
  createAnnualLeave(input: $input, condition: $condition) {
    id
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    tenantAccessString
    startDate
    endDate
    numberOfDays
    status
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    personAnnualLeaveId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAnnualLeaveMutationVariables,
  APITypes.CreateAnnualLeaveMutation
>;
export const updateAnnualLeave = /* GraphQL */ `mutation UpdateAnnualLeave(
  $input: UpdateAnnualLeaveInput!
  $condition: ModelAnnualLeaveConditionInput
) {
  updateAnnualLeave(input: $input, condition: $condition) {
    id
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    tenantAccessString
    startDate
    endDate
    numberOfDays
    status
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    personAnnualLeaveId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAnnualLeaveMutationVariables,
  APITypes.UpdateAnnualLeaveMutation
>;
export const deleteAnnualLeave = /* GraphQL */ `mutation DeleteAnnualLeave(
  $input: DeleteAnnualLeaveInput!
  $condition: ModelAnnualLeaveConditionInput
) {
  deleteAnnualLeave(input: $input, condition: $condition) {
    id
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    tenantAccessString
    startDate
    endDate
    numberOfDays
    status
    period
    audit
    dataSource
    system
    integrationSystemId
    integrationId
    createdAt
    updatedAt
    personAnnualLeaveId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAnnualLeaveMutationVariables,
  APITypes.DeleteAnnualLeaveMutation
>;
export const createProjectRole = /* GraphQL */ `mutation CreateProjectRole(
  $input: CreateProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  createProjectRole(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    fte
    description
    unit
    plannedUnits
    actualUnits
    unitRate
    unitCost
    billingFrequency
    startDate
    endDate
    status
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectProjectRolesId
    pipelineProjectRolesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectRoleMutationVariables,
  APITypes.CreateProjectRoleMutation
>;
export const updateProjectRole = /* GraphQL */ `mutation UpdateProjectRole(
  $input: UpdateProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  updateProjectRole(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    fte
    description
    unit
    plannedUnits
    actualUnits
    unitRate
    unitCost
    billingFrequency
    startDate
    endDate
    status
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectProjectRolesId
    pipelineProjectRolesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectRoleMutationVariables,
  APITypes.UpdateProjectRoleMutation
>;
export const deleteProjectRole = /* GraphQL */ `mutation DeleteProjectRole(
  $input: DeleteProjectRoleInput!
  $condition: ModelProjectRoleConditionInput
) {
  deleteProjectRole(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    fte
    description
    unit
    plannedUnits
    actualUnits
    unitRate
    unitCost
    billingFrequency
    startDate
    endDate
    status
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    pipeline {
      id
      tenantAccessString
      accountAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      amount
      closeDate
      closedNotes
      closingDate
      currency
      dealName
      description
      grossMarginLength
      grossMarginPerMonth
      incomeType
      intro
      managingPartner
      numberOfDeliveryDays
      opportunityLead
      pipeline
      serviceGoLiveDate
      sourceOfferingOpportunity
      totalGrossProfitValue
      totalRevenueValue
      typeOfSale
      createdAt
      updatedAt
      verticalPipelinesId
      accountPipelinesId
      stagePipelinesId
      owner
      __typename
    }
    projectAllocations {
      nextToken
      __typename
    }
    roleTypes {
      nextToken
      __typename
    }
    timesheets {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    projectProjectRolesId
    pipelineProjectRolesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectRoleMutationVariables,
  APITypes.DeleteProjectRoleMutation
>;
export const createProjectAllocation = /* GraphQL */ `mutation CreateProjectAllocation(
  $input: CreateProjectAllocationInput!
  $condition: ModelProjectAllocationConditionInput
) {
  createProjectAllocation(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    startDate
    endDate
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    personProjectAllocationsId
    projectRoleProjectAllocationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectAllocationMutationVariables,
  APITypes.CreateProjectAllocationMutation
>;
export const updateProjectAllocation = /* GraphQL */ `mutation UpdateProjectAllocation(
  $input: UpdateProjectAllocationInput!
  $condition: ModelProjectAllocationConditionInput
) {
  updateProjectAllocation(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    startDate
    endDate
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    personProjectAllocationsId
    projectRoleProjectAllocationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectAllocationMutationVariables,
  APITypes.UpdateProjectAllocationMutation
>;
export const deleteProjectAllocation = /* GraphQL */ `mutation DeleteProjectAllocation(
  $input: DeleteProjectAllocationInput!
  $condition: ModelProjectAllocationConditionInput
) {
  deleteProjectAllocation(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    startDate
    endDate
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    personProjectAllocationsId
    projectRoleProjectAllocationsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectAllocationMutationVariables,
  APITypes.DeleteProjectAllocationMutation
>;
export const createTimesheet = /* GraphQL */ `mutation CreateTimesheet(
  $input: CreateTimesheetInput!
  $condition: ModelTimesheetConditionInput
) {
  createTimesheet(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    timesheetRef
    unit
    unitAmount
    unitRate
    unitCost
    startDate
    endDate
    timeSheetData
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    personTimesheetsId
    projectRoleTimesheetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimesheetMutationVariables,
  APITypes.CreateTimesheetMutation
>;
export const updateTimesheet = /* GraphQL */ `mutation UpdateTimesheet(
  $input: UpdateTimesheetInput!
  $condition: ModelTimesheetConditionInput
) {
  updateTimesheet(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    timesheetRef
    unit
    unitAmount
    unitRate
    unitCost
    startDate
    endDate
    timeSheetData
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    personTimesheetsId
    projectRoleTimesheetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimesheetMutationVariables,
  APITypes.UpdateTimesheetMutation
>;
export const deleteTimesheet = /* GraphQL */ `mutation DeleteTimesheet(
  $input: DeleteTimesheetInput!
  $condition: ModelTimesheetConditionInput
) {
  deleteTimesheet(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    timesheetRef
    unit
    unitAmount
    unitRate
    unitCost
    startDate
    endDate
    timeSheetData
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    personTimesheetsId
    projectRoleTimesheetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimesheetMutationVariables,
  APITypes.DeleteTimesheetMutation
>;
export const createEmployeeSatisfaction = /* GraphQL */ `mutation CreateEmployeeSatisfaction(
  $input: CreateEmployeeSatisfactionInput!
  $condition: ModelEmployeeSatisfactionConditionInput
) {
  createEmployeeSatisfaction(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    date
    score
    comment
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    personEmployeeSatisfactionRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeSatisfactionMutationVariables,
  APITypes.CreateEmployeeSatisfactionMutation
>;
export const updateEmployeeSatisfaction = /* GraphQL */ `mutation UpdateEmployeeSatisfaction(
  $input: UpdateEmployeeSatisfactionInput!
  $condition: ModelEmployeeSatisfactionConditionInput
) {
  updateEmployeeSatisfaction(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    date
    score
    comment
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    personEmployeeSatisfactionRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeSatisfactionMutationVariables,
  APITypes.UpdateEmployeeSatisfactionMutation
>;
export const deleteEmployeeSatisfaction = /* GraphQL */ `mutation DeleteEmployeeSatisfaction(
  $input: DeleteEmployeeSatisfactionInput!
  $condition: ModelEmployeeSatisfactionConditionInput
) {
  deleteEmployeeSatisfaction(input: $input, condition: $condition) {
    id
    tenantAccessString
    dataSource
    system
    integrationSystemId
    integrationId
    audit
    period
    date
    score
    comment
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    personEmployeeSatisfactionRecordsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmployeeSatisfactionMutationVariables,
  APITypes.DeleteEmployeeSatisfactionMutation
>;
export const createWeeklyUpdate = /* GraphQL */ `mutation CreateWeeklyUpdate(
  $input: CreateWeeklyUpdateInput!
  $condition: ModelWeeklyUpdateConditionInput
) {
  createWeeklyUpdate(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    score
    comments
    date
    audit
    createdAt
    updatedAt
    programmeWeeklyUpdatesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWeeklyUpdateMutationVariables,
  APITypes.CreateWeeklyUpdateMutation
>;
export const updateWeeklyUpdate = /* GraphQL */ `mutation UpdateWeeklyUpdate(
  $input: UpdateWeeklyUpdateInput!
  $condition: ModelWeeklyUpdateConditionInput
) {
  updateWeeklyUpdate(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    score
    comments
    date
    audit
    createdAt
    updatedAt
    programmeWeeklyUpdatesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWeeklyUpdateMutationVariables,
  APITypes.UpdateWeeklyUpdateMutation
>;
export const deleteWeeklyUpdate = /* GraphQL */ `mutation DeleteWeeklyUpdate(
  $input: DeleteWeeklyUpdateInput!
  $condition: ModelWeeklyUpdateConditionInput
) {
  deleteWeeklyUpdate(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    score
    comments
    date
    audit
    createdAt
    updatedAt
    programmeWeeklyUpdatesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWeeklyUpdateMutationVariables,
  APITypes.DeleteWeeklyUpdateMutation
>;
export const createCustomDataset = /* GraphQL */ `mutation CreateCustomDataset(
  $input: CreateCustomDatasetInput!
  $condition: ModelCustomDatasetConditionInput
) {
  createCustomDataset(input: $input, condition: $condition) {
    id
    tenantAccessString
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    schema
    permissionsConfig
    customRecords {
      nextToken
      __typename
    }
    audit
    createdAt
    updatedAt
    tenantCustomDatasetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomDatasetMutationVariables,
  APITypes.CreateCustomDatasetMutation
>;
export const updateCustomDataset = /* GraphQL */ `mutation UpdateCustomDataset(
  $input: UpdateCustomDatasetInput!
  $condition: ModelCustomDatasetConditionInput
) {
  updateCustomDataset(input: $input, condition: $condition) {
    id
    tenantAccessString
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    schema
    permissionsConfig
    customRecords {
      nextToken
      __typename
    }
    audit
    createdAt
    updatedAt
    tenantCustomDatasetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomDatasetMutationVariables,
  APITypes.UpdateCustomDatasetMutation
>;
export const deleteCustomDataset = /* GraphQL */ `mutation DeleteCustomDataset(
  $input: DeleteCustomDatasetInput!
  $condition: ModelCustomDatasetConditionInput
) {
  deleteCustomDataset(input: $input, condition: $condition) {
    id
    tenantAccessString
    period
    name
    tenant {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      active
      createdAt
      updatedAt
      __typename
    }
    schema
    permissionsConfig
    customRecords {
      nextToken
      __typename
    }
    audit
    createdAt
    updatedAt
    tenantCustomDatasetsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomDatasetMutationVariables,
  APITypes.DeleteCustomDatasetMutation
>;
export const createChange = /* GraphQL */ `mutation CreateChange(
  $input: CreateChangeInput!
  $condition: ModelChangeConditionInput
) {
  createChange(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    statementOfWork {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    title
    requesterName
    requesterEmail
    organization
    requestType
    requestTitle
    changeRequestArea
    programName
    requestCategory
    requestDetail
    priority
    effectiveDate
    impactDeltaDays
    requestTypeLocation
    financialImpact
    emergencyRequest
    deliveryApproval
    dateDeliveryTeamApproved
    deliveryTeamApprovalNotes
    sltApproval
    sltApprovalDate
    sltApprovalNotes
    impactStatement
    additionalInfo
    newSow
    pmoAdmin
    customerNotified
    audit
    createdAt
    updatedAt
    statementChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChangeMutationVariables,
  APITypes.CreateChangeMutation
>;
export const updateChange = /* GraphQL */ `mutation UpdateChange(
  $input: UpdateChangeInput!
  $condition: ModelChangeConditionInput
) {
  updateChange(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    statementOfWork {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    title
    requesterName
    requesterEmail
    organization
    requestType
    requestTitle
    changeRequestArea
    programName
    requestCategory
    requestDetail
    priority
    effectiveDate
    impactDeltaDays
    requestTypeLocation
    financialImpact
    emergencyRequest
    deliveryApproval
    dateDeliveryTeamApproved
    deliveryTeamApprovalNotes
    sltApproval
    sltApprovalDate
    sltApprovalNotes
    impactStatement
    additionalInfo
    newSow
    pmoAdmin
    customerNotified
    audit
    createdAt
    updatedAt
    statementChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChangeMutationVariables,
  APITypes.UpdateChangeMutation
>;
export const deleteChange = /* GraphQL */ `mutation DeleteChange(
  $input: DeleteChangeInput!
  $condition: ModelChangeConditionInput
) {
  deleteChange(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    projectAccessString
    statementOfWork {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      agreement
      description
      date
      contractType
      days
      durationDays
      location
      valueCredits
      valueUsd
      consumed
      workPackages
      paid
      stage
      completedDate
      createdAt
      updatedAt
      projectStatementsId
      pipelineSowRecordsId
      owner
      __typename
    }
    title
    requesterName
    requesterEmail
    organization
    requestType
    requestTitle
    changeRequestArea
    programName
    requestCategory
    requestDetail
    priority
    effectiveDate
    impactDeltaDays
    requestTypeLocation
    financialImpact
    emergencyRequest
    deliveryApproval
    dateDeliveryTeamApproved
    deliveryTeamApprovalNotes
    sltApproval
    sltApprovalDate
    sltApprovalNotes
    impactStatement
    additionalInfo
    newSow
    pmoAdmin
    customerNotified
    audit
    createdAt
    updatedAt
    statementChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChangeMutationVariables,
  APITypes.DeleteChangeMutation
>;
export const createMRR = /* GraphQL */ `mutation CreateMRR(
  $input: CreateMRRInput!
  $condition: ModelMRRConditionInput
) {
  createMRR(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    fiscalYear
    geo
    region
    subRegion
    territory
    engagementName
    engagementId
    engagementStatus
    engagementType
    businessUnit
    billToCustomer
    endCustomer
    budgetedHoursTotal
    plannedHoursTotal
    createdOn
    paymentType
    revRecType
    billingSKU
    engagementAmount
    billingCurrency
    totalBilledRedeemed
    remainingAmount
    operationAnalysts
    timeApprover
    billedRedeemedByDateRange
    remainingForecastByDateRange
    forecastHrsThisQtr
    forecastRevenueOfCurrentQtr
    forecastRevenueOfCurrentQtrUSD
    totalBillableHoursPriorQtrEnd
    totalBillableHoursThisQtr
    mgmtRevAsOfPriorQtr
    mgmtRevAsOfPriorQtrUSD
    qtrManagementRevenueToDate
    qtrManagementRevenueToDateUSD
    internalHoursQTD
    subbingHoursQTD
    expensesBilledRedeemedByDateRange
    contractStatus
    poNumber
    billedRedeemedByDateRangeUSD
    lastTimeEntryDate
    statusDuringDateRange
    totalBilledRedeemedUSD
    engagementAmountInUSD
    totalActualHours
    hoursBasedForecastRevenueOfCurrentQtr
    hoursBasedForecastRevenueOfCurrentQtrUSD
    plannedHrsForQtr
    program
    projectProfile
    ssm
    projectManager
    engagementClosedDate
    agreementNumber
    opportunityNumber
    psoSubRegion2
    revenueRecognized
    unbilled
    audit
    period
    createdAt
    updatedAt
    programmeMrrsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMRRMutationVariables,
  APITypes.CreateMRRMutation
>;
export const updateMRR = /* GraphQL */ `mutation UpdateMRR(
  $input: UpdateMRRInput!
  $condition: ModelMRRConditionInput
) {
  updateMRR(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    fiscalYear
    geo
    region
    subRegion
    territory
    engagementName
    engagementId
    engagementStatus
    engagementType
    businessUnit
    billToCustomer
    endCustomer
    budgetedHoursTotal
    plannedHoursTotal
    createdOn
    paymentType
    revRecType
    billingSKU
    engagementAmount
    billingCurrency
    totalBilledRedeemed
    remainingAmount
    operationAnalysts
    timeApprover
    billedRedeemedByDateRange
    remainingForecastByDateRange
    forecastHrsThisQtr
    forecastRevenueOfCurrentQtr
    forecastRevenueOfCurrentQtrUSD
    totalBillableHoursPriorQtrEnd
    totalBillableHoursThisQtr
    mgmtRevAsOfPriorQtr
    mgmtRevAsOfPriorQtrUSD
    qtrManagementRevenueToDate
    qtrManagementRevenueToDateUSD
    internalHoursQTD
    subbingHoursQTD
    expensesBilledRedeemedByDateRange
    contractStatus
    poNumber
    billedRedeemedByDateRangeUSD
    lastTimeEntryDate
    statusDuringDateRange
    totalBilledRedeemedUSD
    engagementAmountInUSD
    totalActualHours
    hoursBasedForecastRevenueOfCurrentQtr
    hoursBasedForecastRevenueOfCurrentQtrUSD
    plannedHrsForQtr
    program
    projectProfile
    ssm
    projectManager
    engagementClosedDate
    agreementNumber
    opportunityNumber
    psoSubRegion2
    revenueRecognized
    unbilled
    audit
    period
    createdAt
    updatedAt
    programmeMrrsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMRRMutationVariables,
  APITypes.UpdateMRRMutation
>;
export const deleteMRR = /* GraphQL */ `mutation DeleteMRR(
  $input: DeleteMRRInput!
  $condition: ModelMRRConditionInput
) {
  deleteMRR(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    fiscalYear
    geo
    region
    subRegion
    territory
    engagementName
    engagementId
    engagementStatus
    engagementType
    businessUnit
    billToCustomer
    endCustomer
    budgetedHoursTotal
    plannedHoursTotal
    createdOn
    paymentType
    revRecType
    billingSKU
    engagementAmount
    billingCurrency
    totalBilledRedeemed
    remainingAmount
    operationAnalysts
    timeApprover
    billedRedeemedByDateRange
    remainingForecastByDateRange
    forecastHrsThisQtr
    forecastRevenueOfCurrentQtr
    forecastRevenueOfCurrentQtrUSD
    totalBillableHoursPriorQtrEnd
    totalBillableHoursThisQtr
    mgmtRevAsOfPriorQtr
    mgmtRevAsOfPriorQtrUSD
    qtrManagementRevenueToDate
    qtrManagementRevenueToDateUSD
    internalHoursQTD
    subbingHoursQTD
    expensesBilledRedeemedByDateRange
    contractStatus
    poNumber
    billedRedeemedByDateRangeUSD
    lastTimeEntryDate
    statusDuringDateRange
    totalBilledRedeemedUSD
    engagementAmountInUSD
    totalActualHours
    hoursBasedForecastRevenueOfCurrentQtr
    hoursBasedForecastRevenueOfCurrentQtrUSD
    plannedHrsForQtr
    program
    projectProfile
    ssm
    projectManager
    engagementClosedDate
    agreementNumber
    opportunityNumber
    psoSubRegion2
    revenueRecognized
    unbilled
    audit
    period
    createdAt
    updatedAt
    programmeMrrsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMRRMutationVariables,
  APITypes.DeleteMRRMutation
>;
export const createCost = /* GraphQL */ `mutation CreateCost(
  $input: CreateCostInput!
  $condition: ModelCostConditionInput
) {
  createCost(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    geo
    region
    projectManager
    subRegion
    engagementNumber
    engagementName
    revRecType
    paymentType
    engagementStatus
    engagementCreatedOn
    engagementAmount
    engagementCurrency
    roleName
    billingRateForRole
    budgetedHours
    costRateAverageForResources
    budgetedCostForRole
    plannedHours
    actualHours
    internalPlannedCost
    internalActualCost
    partnerPlannedCostForRole
    partnerActualCostForRole
    audit
    period
    createdAt
    updatedAt
    programmeCostsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCostMutationVariables,
  APITypes.CreateCostMutation
>;
export const updateCost = /* GraphQL */ `mutation UpdateCost(
  $input: UpdateCostInput!
  $condition: ModelCostConditionInput
) {
  updateCost(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    geo
    region
    projectManager
    subRegion
    engagementNumber
    engagementName
    revRecType
    paymentType
    engagementStatus
    engagementCreatedOn
    engagementAmount
    engagementCurrency
    roleName
    billingRateForRole
    budgetedHours
    costRateAverageForResources
    budgetedCostForRole
    plannedHours
    actualHours
    internalPlannedCost
    internalActualCost
    partnerPlannedCostForRole
    partnerActualCostForRole
    audit
    period
    createdAt
    updatedAt
    programmeCostsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCostMutationVariables,
  APITypes.UpdateCostMutation
>;
export const deleteCost = /* GraphQL */ `mutation DeleteCost(
  $input: DeleteCostInput!
  $condition: ModelCostConditionInput
) {
  deleteCost(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    geo
    region
    projectManager
    subRegion
    engagementNumber
    engagementName
    revRecType
    paymentType
    engagementStatus
    engagementCreatedOn
    engagementAmount
    engagementCurrency
    roleName
    billingRateForRole
    budgetedHours
    costRateAverageForResources
    budgetedCostForRole
    plannedHours
    actualHours
    internalPlannedCost
    internalActualCost
    partnerPlannedCostForRole
    partnerActualCostForRole
    audit
    period
    createdAt
    updatedAt
    programmeCostsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCostMutationVariables,
  APITypes.DeleteCostMutation
>;
export const createPL = /* GraphQL */ `mutation CreatePL($input: CreatePLInput!, $condition: ModelPLConditionInput) {
  createPL(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    engId
    engagementName
    status
    gar
    projectManager
    subcontractor
    lastInvoice
    budgetMarginType
    impactedByAllInCostChange
    deAuditMessageUSD
    billingCurrency
    budgetComments
    budgetGar
    csatComments
    csatGar
    engagementStatus
    engagementType
    fisYear2QtrName
    lastBillingEntry
    mitigationAction
    projectGarComments
    geo
    region
    subRegion
    scheduleComments
    scheduleGar
    deliveryManager
    deAuditResultsUSD
    revenueSoldUSD
    revenueRecognizedUSD
    billedRedeemedUSD
    costSoldUSD
    marginSold
    revisedCostSoldUSD
    revisedMarginSold
    percentOfHoursBeforeAllInChange
    revenueForecastExitUSD
    costForecastExitUSD
    marginForecastExit
    revenuePlannedUSD
    costPlannedUSD
    marginPlanned
    revenueActualUSD
    costActualUSD
    marginActual
    hoursSold
    hoursPlanned
    hoursActual
    percentCompleteHoursSold
    revenueSoldBC
    maxExchangeRateUsed
    billedRedeemedBC
    costForecastExitBC
    costSoldBC
    revenueForecastExitBC
    revenueRecognizedBC
    audit
    period
    createdAt
    updatedAt
    programmePlsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePLMutationVariables,
  APITypes.CreatePLMutation
>;
export const updatePL = /* GraphQL */ `mutation UpdatePL($input: UpdatePLInput!, $condition: ModelPLConditionInput) {
  updatePL(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    engId
    engagementName
    status
    gar
    projectManager
    subcontractor
    lastInvoice
    budgetMarginType
    impactedByAllInCostChange
    deAuditMessageUSD
    billingCurrency
    budgetComments
    budgetGar
    csatComments
    csatGar
    engagementStatus
    engagementType
    fisYear2QtrName
    lastBillingEntry
    mitigationAction
    projectGarComments
    geo
    region
    subRegion
    scheduleComments
    scheduleGar
    deliveryManager
    deAuditResultsUSD
    revenueSoldUSD
    revenueRecognizedUSD
    billedRedeemedUSD
    costSoldUSD
    marginSold
    revisedCostSoldUSD
    revisedMarginSold
    percentOfHoursBeforeAllInChange
    revenueForecastExitUSD
    costForecastExitUSD
    marginForecastExit
    revenuePlannedUSD
    costPlannedUSD
    marginPlanned
    revenueActualUSD
    costActualUSD
    marginActual
    hoursSold
    hoursPlanned
    hoursActual
    percentCompleteHoursSold
    revenueSoldBC
    maxExchangeRateUsed
    billedRedeemedBC
    costForecastExitBC
    costSoldBC
    revenueForecastExitBC
    revenueRecognizedBC
    audit
    period
    createdAt
    updatedAt
    programmePlsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePLMutationVariables,
  APITypes.UpdatePLMutation
>;
export const deletePL = /* GraphQL */ `mutation DeletePL($input: DeletePLInput!, $condition: ModelPLConditionInput) {
  deletePL(input: $input, condition: $condition) {
    id
    tenantAccessString
    verticalAccessString
    accountAccessString
    programmeAccessString
    pmAccess
    dmAccess
    programme {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      verticalProgrammesId
      accountProgrammesId
      owner
      __typename
    }
    engId
    engagementName
    status
    gar
    projectManager
    subcontractor
    lastInvoice
    budgetMarginType
    impactedByAllInCostChange
    deAuditMessageUSD
    billingCurrency
    budgetComments
    budgetGar
    csatComments
    csatGar
    engagementStatus
    engagementType
    fisYear2QtrName
    lastBillingEntry
    mitigationAction
    projectGarComments
    geo
    region
    subRegion
    scheduleComments
    scheduleGar
    deliveryManager
    deAuditResultsUSD
    revenueSoldUSD
    revenueRecognizedUSD
    billedRedeemedUSD
    costSoldUSD
    marginSold
    revisedCostSoldUSD
    revisedMarginSold
    percentOfHoursBeforeAllInChange
    revenueForecastExitUSD
    costForecastExitUSD
    marginForecastExit
    revenuePlannedUSD
    costPlannedUSD
    marginPlanned
    revenueActualUSD
    costActualUSD
    marginActual
    hoursSold
    hoursPlanned
    hoursActual
    percentCompleteHoursSold
    revenueSoldBC
    maxExchangeRateUsed
    billedRedeemedBC
    costForecastExitBC
    costSoldBC
    revenueForecastExitBC
    revenueRecognizedBC
    audit
    period
    createdAt
    updatedAt
    programmePlsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePLMutationVariables,
  APITypes.DeletePLMutation
>;
export const createDeliveryManagerProjects = /* GraphQL */ `mutation CreateDeliveryManagerProjects(
  $input: CreateDeliveryManagerProjectsInput!
  $condition: ModelDeliveryManagerProjectsConditionInput
) {
  createDeliveryManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeliveryManagerProjectsMutationVariables,
  APITypes.CreateDeliveryManagerProjectsMutation
>;
export const updateDeliveryManagerProjects = /* GraphQL */ `mutation UpdateDeliveryManagerProjects(
  $input: UpdateDeliveryManagerProjectsInput!
  $condition: ModelDeliveryManagerProjectsConditionInput
) {
  updateDeliveryManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeliveryManagerProjectsMutationVariables,
  APITypes.UpdateDeliveryManagerProjectsMutation
>;
export const deleteDeliveryManagerProjects = /* GraphQL */ `mutation DeleteDeliveryManagerProjects(
  $input: DeleteDeliveryManagerProjectsInput!
  $condition: ModelDeliveryManagerProjectsConditionInput
) {
  deleteDeliveryManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeliveryManagerProjectsMutationVariables,
  APITypes.DeleteDeliveryManagerProjectsMutation
>;
export const createProjectManagerProjects = /* GraphQL */ `mutation CreateProjectManagerProjects(
  $input: CreateProjectManagerProjectsInput!
  $condition: ModelProjectManagerProjectsConditionInput
) {
  createProjectManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectManagerProjectsMutationVariables,
  APITypes.CreateProjectManagerProjectsMutation
>;
export const updateProjectManagerProjects = /* GraphQL */ `mutation UpdateProjectManagerProjects(
  $input: UpdateProjectManagerProjectsInput!
  $condition: ModelProjectManagerProjectsConditionInput
) {
  updateProjectManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectManagerProjectsMutationVariables,
  APITypes.UpdateProjectManagerProjectsMutation
>;
export const deleteProjectManagerProjects = /* GraphQL */ `mutation DeleteProjectManagerProjects(
  $input: DeleteProjectManagerProjectsInput!
  $condition: ModelProjectManagerProjectsConditionInput
) {
  deleteProjectManagerProjects(input: $input, condition: $condition) {
    id
    projectID
    personID
    project {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      tcv
      margin
      workstream
      currency
      budget
      spend
      country
      status
      startDate
      endDate
      revenueSold
      costSold
      revenueActual
      costActual
      consumed
      createdAt
      updatedAt
      programmeProjectsId
      regionProjectsId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectManagerProjectsMutationVariables,
  APITypes.DeleteProjectManagerProjectsMutation
>;
export const createPersonRisks = /* GraphQL */ `mutation CreatePersonRisks(
  $input: CreatePersonRisksInput!
  $condition: ModelPersonRisksConditionInput
) {
  createPersonRisks(input: $input, condition: $condition) {
    id
    riskID
    personID
    risk {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePersonRisksMutationVariables,
  APITypes.CreatePersonRisksMutation
>;
export const updatePersonRisks = /* GraphQL */ `mutation UpdatePersonRisks(
  $input: UpdatePersonRisksInput!
  $condition: ModelPersonRisksConditionInput
) {
  updatePersonRisks(input: $input, condition: $condition) {
    id
    riskID
    personID
    risk {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePersonRisksMutationVariables,
  APITypes.UpdatePersonRisksMutation
>;
export const deletePersonRisks = /* GraphQL */ `mutation DeletePersonRisks(
  $input: DeletePersonRisksInput!
  $condition: ModelPersonRisksConditionInput
) {
  deletePersonRisks(input: $input, condition: $condition) {
    id
    riskID
    personID
    risk {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      description
      actionOwner
      status
      impactDate
      cause
      effect
      impactDescription
      impactType
      riskResponse
      actionMitigation
      targetResolutionDate
      probability
      impact
      estimatedImpactCost
      escalatedToPortfolio
      detailedUpdate
      dateRaised
      dateOfNextReview
      createdAt
      updatedAt
      verticalRisksId
      accountRisksId
      programmeRisksId
      projectRisksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePersonRisksMutationVariables,
  APITypes.DeletePersonRisksMutation
>;
export const createPersonTasks = /* GraphQL */ `mutation CreatePersonTasks(
  $input: CreatePersonTasksInput!
  $condition: ModelPersonTasksConditionInput
) {
  createPersonTasks(input: $input, condition: $condition) {
    id
    taskID
    personID
    task {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      category
      type
      status
      startDate
      endDate
      completedDate
      createdAt
      updatedAt
      projectTasksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePersonTasksMutationVariables,
  APITypes.CreatePersonTasksMutation
>;
export const updatePersonTasks = /* GraphQL */ `mutation UpdatePersonTasks(
  $input: UpdatePersonTasksInput!
  $condition: ModelPersonTasksConditionInput
) {
  updatePersonTasks(input: $input, condition: $condition) {
    id
    taskID
    personID
    task {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      category
      type
      status
      startDate
      endDate
      completedDate
      createdAt
      updatedAt
      projectTasksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePersonTasksMutationVariables,
  APITypes.UpdatePersonTasksMutation
>;
export const deletePersonTasks = /* GraphQL */ `mutation DeletePersonTasks(
  $input: DeletePersonTasksInput!
  $condition: ModelPersonTasksConditionInput
) {
  deletePersonTasks(input: $input, condition: $condition) {
    id
    taskID
    personID
    task {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      complete
      category
      type
      status
      startDate
      endDate
      completedDate
      createdAt
      updatedAt
      projectTasksId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePersonTasksMutationVariables,
  APITypes.DeletePersonTasksMutation
>;
export const createRoleTypePeople = /* GraphQL */ `mutation CreateRoleTypePeople(
  $input: CreateRoleTypePeopleInput!
  $condition: ModelRoleTypePeopleConditionInput
) {
  createRoleTypePeople(input: $input, condition: $condition) {
    id
    roleTypeID
    personID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleTypePeopleMutationVariables,
  APITypes.CreateRoleTypePeopleMutation
>;
export const updateRoleTypePeople = /* GraphQL */ `mutation UpdateRoleTypePeople(
  $input: UpdateRoleTypePeopleInput!
  $condition: ModelRoleTypePeopleConditionInput
) {
  updateRoleTypePeople(input: $input, condition: $condition) {
    id
    roleTypeID
    personID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleTypePeopleMutationVariables,
  APITypes.UpdateRoleTypePeopleMutation
>;
export const deleteRoleTypePeople = /* GraphQL */ `mutation DeleteRoleTypePeople(
  $input: DeleteRoleTypePeopleInput!
  $condition: ModelRoleTypePeopleConditionInput
) {
  deleteRoleTypePeople(input: $input, condition: $condition) {
    id
    roleTypeID
    personID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    person {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      firstName
      lastName
      email
      position
      location
      hoursPerWeek
      currentlyActive
      skills
      createdAt
      updatedAt
      tenantPeopleId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleTypePeopleMutationVariables,
  APITypes.DeleteRoleTypePeopleMutation
>;
export const createRoleTypeProjectRoles = /* GraphQL */ `mutation CreateRoleTypeProjectRoles(
  $input: CreateRoleTypeProjectRolesInput!
  $condition: ModelRoleTypeProjectRolesConditionInput
) {
  createRoleTypeProjectRoles(input: $input, condition: $condition) {
    id
    roleTypeID
    projectRoleID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleTypeProjectRolesMutationVariables,
  APITypes.CreateRoleTypeProjectRolesMutation
>;
export const updateRoleTypeProjectRoles = /* GraphQL */ `mutation UpdateRoleTypeProjectRoles(
  $input: UpdateRoleTypeProjectRolesInput!
  $condition: ModelRoleTypeProjectRolesConditionInput
) {
  updateRoleTypeProjectRoles(input: $input, condition: $condition) {
    id
    roleTypeID
    projectRoleID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleTypeProjectRolesMutationVariables,
  APITypes.UpdateRoleTypeProjectRolesMutation
>;
export const deleteRoleTypeProjectRoles = /* GraphQL */ `mutation DeleteRoleTypeProjectRoles(
  $input: DeleteRoleTypeProjectRolesInput!
  $condition: ModelRoleTypeProjectRolesConditionInput
) {
  deleteRoleTypeProjectRoles(input: $input, condition: $condition) {
    id
    roleTypeID
    projectRoleID
    roleType {
      id
      tenantAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      name
      createdAt
      updatedAt
      tenantRoleTypesId
      owner
      __typename
    }
    projectRole {
      id
      tenantAccessString
      verticalAccessString
      accountAccessString
      programmeAccessString
      projectAccessString
      dataSource
      system
      integrationSystemId
      integrationId
      audit
      period
      fte
      description
      unit
      plannedUnits
      actualUnits
      unitRate
      unitCost
      billingFrequency
      startDate
      endDate
      status
      createdAt
      updatedAt
      projectProjectRolesId
      pipelineProjectRolesId
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleTypeProjectRolesMutationVariables,
  APITypes.DeleteRoleTypeProjectRolesMutation
>;
