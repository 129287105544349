import { CustomDataset } from "../../../API";
import {
  risksSchema,
  changesSchema,
  peopleSchema,
  csatsSchema,
  weeklyUpdatesSchema,
  deliverablesSchema,
  statementsSchema,
  projectsSchema,
  mrrSchema,
  costsSchema,
  plSchema,
  accountsSchema,
  verticalsSchema,
  programmesSchema,
  invoiceSchema,
  employeeSatisfactionSchema,
  pipelineSchema,
  projectAllocationSchema,
  projectRoleSchema,
  roleTypeSchema,
  taskSchema,
  tenantsSchema,
  timeSheetSchema,
  userTypeSchema,
  annualLeaveSchema,
  targetsSchema,
} from "../../../context/dataSchema";
import { ISchema } from "../../../context/SchemaTypes";

type ISchemaLookup = {
  [dataSource: string]: { schema: ISchema; name: string };
};

const schemaLookup: ISchemaLookup = {
  risks: { schema: risksSchema, name: "Risks" },
  changes: { schema: changesSchema, name: "Change Requests" },
  people: { schema: peopleSchema, name: "People" },
  csats: { schema: csatsSchema, name: "CSATs" },
  weeklyUpdates: { schema: weeklyUpdatesSchema, name: "Weekly Updates" },
  deliverables: { schema: deliverablesSchema, name: "Deliverables" },
  statements: { schema: statementsSchema, name: "Statements" },
  projects: { schema: projectsSchema, name: "Projects" },
  mrrs: { schema: mrrSchema, name: "MRRs" },
  costs: { schema: costsSchema, name: "Costs" },
  pls: { schema: plSchema, name: "P&Ls" },
  annualLeaves: { schema: annualLeaveSchema, name: "Annual Leaves" },

  accounts: { schema: accountsSchema, name: "Accounts" },
  verticals: { schema: verticalsSchema, name: "Verticals" },
  programmes: { schema: programmesSchema, name: "Programmes" },
  invoices: { schema: invoiceSchema, name: "Invoices" },
  employeeSatisfaction: {
    schema: employeeSatisfactionSchema,
    name: "Employee Satisfaction",
  },
  pipelines: { schema: pipelineSchema, name: "Pipelines" },
  projectAllocations: {
    schema: projectAllocationSchema,
    name: "Project Allocations",
  },
  projectRoles: { schema: projectRoleSchema, name: "Project Roles" },
  // regions: { schema: regio },
  roleTypes: { schema: roleTypeSchema, name: "Role Types" },
  tasks: { schema: taskSchema, name: "Tasks" },
  tenants: { schema: tenantsSchema, name: "Tenants" },
  timesheets: { schema: timeSheetSchema, name: "Timesheets" },
  userTypes: { schema: userTypeSchema, name: "User Types" },
  targets: { schema: targetsSchema, name: "Targets" },
};

export function getDataSchema(
  dataKey: string,
  customDatasets: CustomDataset[] = []
): ISchema {
  if (schemaLookup[dataKey]) {
    return schemaLookup[dataKey as keyof ISchemaLookup].schema;
  } else {
    const customDataset = customDatasets.find(
      (customDataset) => customDataset.id === dataKey
    );
    return customDataset
      ? JSON.parse(
          //@ts-ignore
          customDataset?.schema
        )
      : {};
  }
}
