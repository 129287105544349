import React, { useMemo } from "react";
import useStyles from "./FinanceVisual.styles";
import { useFinanceVisual } from "./useFinanceVisual";
import WidgetFrame from "../../WidgetFrame";
import {
  Group,
  Progress,
  Skeleton,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import { nFormatter } from "../../functions/formatFunctions";
import { calculatePercentageOfDurationPassed } from "../../functions/dateFunctions";
import { IconInfoCircle } from "@tabler/icons-react";

export type FinanceVisualProps = {};

export function NewFinanceVisual({
  dashboardFilterObjectList,
  startDate,
  endDate,
}: any) {
  const { classes, theme } = useStyles();

  const {
    total,
    paid,
    paidPercentage,
    consumed,
    consumedSurplusPercentage,
    remainingValue,
    remainingPercentage,
    loading,
    ref,
    filteredProjects,
    unclaimed,
    unclaimedPercentage,
  } = useFinanceVisual({
    dashboardFilterObjectList,
    startDate,
    endDate,
  });

  const PercentageTimePassedWidth = useMemo(() => {
    if (filteredProjects.length === 1) {
      const { startDate, endDate } = filteredProjects[0];

      const percentage = calculatePercentageOfDurationPassed({
        startDate,
        endDate,
      });

      return (
        <div
          style={{
            position: "absolute",
            left: percentage * (ref.current?.offsetWidth ?? 0),
            top: 0,
            width: 2,
            height: "100%",
            backgroundColor: "white",
          }}
        ></div>
      );
    } else {
      return <></>;
    }
  }, [ref, filteredProjects]);

  return (
    <WidgetFrame title={"Project Revenue Status"} icon={IconCalendar}>
      {/* <Group align="flex-end" spacing="xs" sx={{ width: "100%" }}> */}
      {/* <Group sx={{ width: "100%" }} spacing={0}>
        <Group
          sx={{
            minWidth: "fit-content",
            width: `${paidPercentage.toFixed(0)}%`,
          }}
          spacing={"xs"}
        >
          <Text size="xs" color="teal" weight={700}>
            Claimed
          </Text>
          <Tooltip label="Value of paid invoices">
            <ThemeIcon variant="light" radius="xl" size="sm" color="green">
              <IconInfoCircle />
            </ThemeIcon>
          </Tooltip>
        </Group>
        <Group
          sx={{
            minWidth: "fit-content",
            width: `${consumedSurplusPercentage.toFixed(0)}%`,
          }}
          spacing={"xs"}
        >
          <Text size="xs" color="yellow" weight={700}>
            Recognised
          </Text>
          <Tooltip label="Unpaid revenue of in progress projects">
            <ThemeIcon variant="light" radius="xl" size="sm" color="orange">
              <IconInfoCircle />
            </ThemeIcon>
          </Tooltip>
        </Group>
        <Group
          sx={{
            minWidth: "fit-content",
            width: `${remainingPercentage.toFixed(0)}%`,
          }}
          spacing={"xs"}
        >
          <Text size="xs" color="orange" weight={700}>
            Forecast
          </Text>
          <Tooltip label="Forecast revenue on upcoming projects">
            <ThemeIcon variant="light" radius="xl" size="sm" color="yellow">
              <IconInfoCircle />
            </ThemeIcon>
          </Tooltip>
        </Group>
        <Group sx={{ minWidth: "fit-content" }} spacing={"xs"}>
          <Text size="xs" color="red" weight={700}>
            Unclaimed
          </Text>
          <Tooltip label="Unclaimed revenue on finished projects">
            <ThemeIcon variant="light" radius="xl" size="sm" color="red">
              <IconInfoCircle />
            </ThemeIcon>
          </Tooltip>
        </Group>
      </Group> */}

      {/* <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xl" color="teal" weight={700}>
            £{nFormatter(paid, 1)}{" "}
            {total > 0 ? `(${paidPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="yellow" weight={700}>
            £{nFormatter(consumed, 1)}{" "}
            {total > 0 ? `(${consumedSurplusPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="orange" weight={700}>
            £{nFormatter(remainingValue, 1)}{" "}
            {total > 0 ? `(${remainingPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="red" weight={700}>
            £{nFormatter(unclaimed, 1)}{" "}
            {total > 0 ? `(${unclaimedPercentage.toFixed(0)}%)` : ""}
          </Text>
        </Group> */}
      {loading && <Skeleton sx={{ width: "100%", height: 20 }} />}
      {!loading && (
        <div style={{ width: "100%", position: "relative" }}>
          <Progress
            ref={ref}
            size={24}
            classNames={{ bar: classes.progressBar }}
            // sx={{ width: "100%" }}
            sections={[
              {
                value: paidPercentage,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.teal[9]
                    : theme.colors.teal[6],
                label: `Paid £${nFormatter(paid, 1)} ${
                  total > 0 ? `(${paidPercentage.toFixed(0)}%)` : ""
                }`,
                tooltip: "Value of paid invoices",
              },
              {
                value: consumedSurplusPercentage,
                color: "orange",
                label: `Claimed £${nFormatter(consumed, 1)} ${
                  total > 0 ? `(${consumedSurplusPercentage.toFixed(0)}%)` : ""
                }`,
                tooltip: "Unpaid revenue of in progress projects",
              },
              {
                value: remainingPercentage,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.orange[9]
                    : theme.colors.orange[6],
                label: `Forecast £${nFormatter(remainingValue, 1)} ${
                  total > 0 ? `(${remainingPercentage.toFixed(0)}%)` : ""
                }`,
                tooltip: "Forecast revenue on upcoming projects",
              },
              {
                value: unclaimedPercentage,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.red[9]
                    : theme.colors.red[6],
                label: `Unclaimed £${nFormatter(unclaimed, 1)} ${
                  total > 0 ? `(${unclaimedPercentage.toFixed(0)}%)` : ""
                }`,
                tooltip: "Unclaimed revenue on finished projects",
              },
            ]}
          />
          {PercentageTimePassedWidth}
        </div>
      )}
      {/* </Group> */}
    </WidgetFrame>
  );
}
