import React, { useMemo } from "react";
import useStyles from "./FinanceVisual.styles";
import { useFinanceVisual } from "./useFinanceVisual";
import WidgetFrame from "../../WidgetFrame";
import { Group, Progress, Skeleton, Text } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import { nFormatter } from "../../functions/formatFunctions";
import { calculatePercentageOfDurationPassed } from "../../functions/dateFunctions";

export type FinanceVisualProps = {};

export function FinanceVisual({
  dashboardFilterObjectList,
  startDate,
  endDate,
}: any) {
  const { classes, theme } = useStyles();

  const {
    total,
    paid,
    paidPercentage,
    surplusConsumed,
    consumedSurplusPercentage,
    remainingValue,
    remainingPercentage,
    loading,
    ref,
    filteredProjects,
  } = useFinanceVisual({
    dashboardFilterObjectList,
    startDate,
    endDate,
  });

  const PercentageTimePassedWidth = useMemo(() => {
    if (filteredProjects.length === 1) {
      const { startDate, endDate } = filteredProjects[0];

      const percentage = calculatePercentageOfDurationPassed({
        startDate,
        endDate,
      });

      return (
        <div
          style={{
            position: "absolute",
            left: percentage * (ref.current?.offsetWidth ?? 0),
            top: 0,
            width: 2,
            height: "100%",
            backgroundColor: "white",
          }}
        ></div>
      );
    } else {
      return <></>;
    }
  }, [ref, filteredProjects]);

  return (
    <WidgetFrame title={"Project Revenue"} icon={IconCalendar}>
      <Group align="flex-end" spacing="xs" sx={{ width: "100%" }}>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xs" color="teal" weight={700}>
            Revenue Claimed
          </Text>
          <Text size="xs" color="orange" weight={700}>
            Revenue Recognised
          </Text>
          <Text size="xs" color="red" weight={700}>
            Revenue Forecast
          </Text>
        </Group>
        <Group position="apart" sx={{ width: "100%" }}>
          <Text size="xl" color="teal" weight={700}>
            £{nFormatter(paid, 1)}{" "}
            {total > 0 ? `(${paidPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="orange" weight={700}>
            £{nFormatter(surplusConsumed, 1)}{" "}
            {total > 0 ? `(${consumedSurplusPercentage.toFixed(0)}%)` : ""}
          </Text>
          <Text size="xl" color="red" weight={700}>
            £{nFormatter(remainingValue, 1)}{" "}
            {total > 0 ? `(${remainingPercentage.toFixed(0)}%)` : ""}
          </Text>
        </Group>
        {loading && <Skeleton sx={{ width: "100%", height: 20 }} />}
        {!loading && (
          <div style={{ width: "100%", position: "relative" }}>
            <Progress
              ref={ref}
              classNames={{ bar: classes.progressBar }}
              sx={{ width: "100%", height: 20 }}
              sections={[
                {
                  value: paidPercentage,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.teal[9]
                      : theme.colors.teal[6],
                },
                {
                  value: consumedSurplusPercentage,
                  color: "orange",
                },
                {
                  value: remainingPercentage,
                  color:
                    theme.colorScheme === "dark"
                      ? theme.colors.red[9]
                      : theme.colors.red[6],
                },
              ]}
            />
            {PercentageTimePassedWidth}
          </div>
        )}
      </Group>
    </WidgetFrame>
  );
}
