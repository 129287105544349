import { useContext } from "react";
import CrudScreen from "../common/CrudScreen";
import AppContext from "../../context/AppContext";
import { targetsSchema as schema } from "../../context/dataSchema";
import {
  createTarget as createQuery,
  updateTarget as updateQuery,
  deleteTarget as deleteQuery,
} from "../../graphql/mutations";

export default function Targets(props: any) {
  const { dataObject } = useContext(AppContext);
  const { targets: records = [] } = dataObject;

  const screenProps = {
    data: records,
    schema,
    title: "Targets",
    quickStats: null,
    tableColumns: ["module", "field", "value", "startDate", "endDate"],
    filterFields: [],
    recordName: "Target",
    pagePath: "targets",
    formProps: {
      createQuery,
      updateQuery,
      deleteQuery,
    },
    dataObjectKey: "targets",
    modulesToLoad: ["Targets"],
  };
  return <CrudScreen {...screenProps} />;
}
