import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import { getSystemPreference } from "../graphql/queries";
import {
  createSystemPreference,
  updateSystemPreference,
} from "../graphql/mutations";
import { ISystemPreferenceObject } from "../types/systemPreferencesTypes";

const defaultPreferenceObject: ISystemPreferenceObject = {
  appearance: {
    systemTitle: "",
    primaryColor: "blue",
  },
  customers: {},
  modules: {
    Accounts: {
      active: true,
      dataSource: "database",
      dataKey: "accounts",
    },
    Risks: {
      active: true,
      dataSource: "database",
      dataKey: "risks",
    },
    People: {
      active: true,
      dataSource: "database",
      dataKey: "people",
    },
    Projects: {
      active: true,
      dataSource: "database",
      dataKey: "projects",
    },
    Tenants: {
      active: true,
      dataSource: "database",
      dataKey: "tenants",
    },
    "Statements of Work": {
      active: true,
      dataSource: "database",
      dataKey: "statements",
    },
    Deliverables: {
      active: true,
      dataSource: "database",
      dataKey: "deliverables",
    },
    "Change Requests": {
      active: true,
      dataSource: "database",
      dataKey: "changes",
    },
    CSATs: {
      active: true,
      dataSource: "database",
      dataKey: "csats",
    },
    "Weekly Updates": {
      active: true,
      dataSource: "database",
      dataKey: "weeklyUpdates",
    },
    // MRRs: {
    //   active: true,
    //   dataSource: "database",
    //   dataKey: "mrrs",
    // },
    // Costs: {
    //   active: true,
    //   dataSource: "database",
    //   dataKey: "costs",
    // },
    // PLs: {
    //   active: true,
    //   dataSource: "database",
    //   dataKey: "pls",
    // },
    Programmes: {
      active: true,
      dataSource: "database",
      dataKey: "programmes",
    },
    Verticals: {
      active: true,
      dataSource: "database",
      dataKey: "verticals",
    },

    Invoices: {
      active: true,
      dataSource: "database",
      dataKey: "invoices",
    },
    "Employee Satisfaction": {
      active: true,
      dataSource: "database",
      dataKey: "employeeSatisfaction",
    },
    Pipelines: {
      active: true,
      dataSource: "database",
      dataKey: "pipelines",
    },
    "Project Allocations": {
      active: true,
      dataSource: "database",
      dataKey: "projectAllocations",
    },
    "Project Roles": {
      active: true,
      dataSource: "database",
      dataKey: "projectRoles",
    },
    Regions: {
      active: true,
      dataSource: "database",
      dataKey: "regions",
    },

    "Role Types": {
      active: true,
      dataSource: "database",
      dataKey: "roleTypes",
    },
    Tasks: {
      active: true,
      dataSource: "database",
      dataKey: "tasks",
    },
    "Time Sheets": {
      active: true,
      dataSource: "database",
      dataKey: "timesheets",
    },
    "Annual Leave": {
      active: true,
      dataSource: "database",
      dataKey: "annualLeaves",
    },
    Targets: {
      active: true,
      dataSource: "database",
      dataKey: "targets",
    },
  },
};

export const useSystemPreferences = ({
  ownerEmail,
  setColor,
  viewType,
  account,
}: {
  ownerEmail: string | null;
  setColor: any;
  viewType: any;
  account: any;
}) => {
  const [preferenceObject, setPreferenceObject] =
    useState<ISystemPreferenceObject | null>(null);

  const updatePreferenceObject = useCallback(async () => {
    const queryObject: any = {
      query: getSystemPreference,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { ownerEmail },
    };
    const response: any = await API.graphql(queryObject);

    if (!response.data.getSystemPreference) {
      await API.graphql({
        query: createSystemPreference,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            ownerEmail,
            tenantAccessString: ownerEmail,
            preferenceObject: JSON.stringify(defaultPreferenceObject),
          },
        },
      });
      setPreferenceObject(defaultPreferenceObject);
    } else {
      const { preferenceObject } = response.data.getSystemPreference;
      const parsedPreferenceObject = JSON.parse(preferenceObject);

      const { modules } = defaultPreferenceObject;

      for (let module in modules) {
        if (!parsedPreferenceObject.modules[module]) {
          //@ts-ignore
          parsedPreferenceObject.modules[module] = modules[module];
        }
      }

      for (let module in parsedPreferenceObject.modules) {
        //@ts-ignore
        if (!modules[module]) {
          delete parsedPreferenceObject.modules[module];
        }
      }

      setPreferenceObject(parsedPreferenceObject);
    }
  }, [ownerEmail]);

  useEffect(() => {
    if (ownerEmail) {
      updatePreferenceObject();
    }
  }, [ownerEmail]);

  useEffect(() => {
    let color = window.localStorage.getItem("smartsync-primary-color");
    if (preferenceObject) {
      color =
        viewType === "Internal"
          ? preferenceObject?.appearance.primaryColor
          : preferenceObject?.customers && preferenceObject.customers[account]
          ? preferenceObject?.customers[account].primaryColor
          : "blue";
    }

    setColor(color);
    if (color) {
      window.localStorage.setItem("smartsync-primary-color", color);
    }
  }, [preferenceObject, setColor]);

  return { preferenceObject, setPreferenceObject };
};
