import common from "./commonSchema";
import {
  formatDateString,
  formatCurrency,
} from "../../components/common/functions/formatFunctions";
import { ISchema } from "../SchemaTypes";

const statementSchema: ISchema = {
  integrationSystemId: {
    title: "Integration System ID",
    field: "integrationSystemId",
    excludeFromForm: true,
    excludeFromChartBuilder: true,
    formOptions: {
      required: false,
    },
  },
  pipeline: {
    title: "Pipeline",
    field: "pipeline",
    accessorFunction: (value: any) => value?.pipeline?.dealName,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  pipelineSowRecordsId: {
    title: "Pipeline",
    field: "pipelineSowRecordsId",
    isKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "pipelines",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource?.map((row: any) => {
            return { value: row.id, label: row.dealName };
          }) ?? [],
      },
      order: 1,
    },
    type: "select",
  },

  project: {
    title: "Project",
    field: "project",
    accessorFunction: (value: any) => value.project?.name,
    formatFunction: (value: any) => value,
    formOptions: { required: true },
    excludeFromForm: true,
    type: "belongsTo",
  },
  projectStatementsId: {
    title: "Project",
    field: "projectStatementsId",
    isKey: true,
    formOptions: {
      required: true,
      options: {
        dataSource: "projects",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          dataSource.map((row: any) => {
            return { value: row.id, label: row.name };
          }),
      },
      order: 1,
    },
    type: "select",
  },

  agreement: {
    title: "Agreement",
    field: "agreement",
    formOptions: { required: false },
  },
  description: {
    title: "Description",
    field: "description",
    type: "textarea",
    formOptions: { required: false },
  },
  date: {
    title: "Date",
    field: "date",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  contractType: {
    title: "Contract Type",
    field: "contractType",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: null,
      options: { list: ["Fixed Fee", "Time and Material"] },
    },
    filterOptions: {
      defaultValue: "All",
      includeAll: true,
    },
  },
  days: {
    title: "Days",
    field: "days",
    type: "number",
    formOptions: { required: false },
  },
  durationDays: {
    title: "Duration (Days)",
    field: "durationDays",
    type: "number",
    formOptions: { required: false },
  },
  location: {
    title: "Location",
    field: "location",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: null,
      options: { list: ["Onsite / Remote", "Remote", "Onsite"] },
    },
    filterOptions: {
      defaultValue: "All",
      includeAll: true,
    },
  },
  valueCredits: {
    title: "Value Credits",
    field: "valueCredits",
    type: "number",
    formOptions: { required: false },
  },
  valueUsd: {
    title: "Value (USD)",
    field: "valueUsd",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "$"),
    formOptions: { required: false },
  },
  consumed: {
    title: "Consumed",
    field: "consumed",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "$"),
    formOptions: { required: false },
  },
  workPackages: {
    title: "Work Packages",
    field: "workPackages",
    type: "number",
    formOptions: { required: false },
  },
  paid: {
    title: "Paid",
    field: "paid",
    type: "number",
    formatFunction: (value: any) => formatCurrency(value, "$"),
    formOptions: { required: false },
  },

  changes: {
    title: "Changes",
    field: "changes",
    type: "hasMany",
    accessorFunction: (row: any) => row.changes.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  deliverables: {
    title: "Deliverables",
    field: "deliverables",
    type: "hasMany",
    accessorFunction: (row: any) => row.deliverables.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  invoices: {
    title: "Invoices",
    field: "invoices",
    type: "hasMany",
    accessorFunction: (row: any) => row.invoices.length,
    formOptions: { required: true },
    excludeFromForm: true,
  },
  completedDate: {
    title: "Completed Date",
    field: "completedDate",
    type: "date",
    formatFunction: formatDateString,
    formOptions: { required: false },
  },
  ...common,
};

export default statementSchema;
