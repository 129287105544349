import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Group, Modal, Table, Title } from "@mantine/core";
import CustomPaper from "../common/CustomPaper";
// import FieldMappingRow from "./FieldMappingRow";
import FieldMappingRow from "./FieldMappingRow";
import { getDataSchema } from "../common/functions/getDataSchema";
import { useDisclosure } from "@mantine/hooks";
import { CreateRules } from "./CreateRules";
import AppContext from "../../context/AppContext";

// const defaultMapping = {
//   name: "Deal_Name",
//   integrationSystemId: "id",
//   startDate: "Service_go_live_date",
//   endDate: "Close_Date",
//   tcv: "Total_Rev_Value",
//   status: "Stage",
//   margin: "Total_GP_Value",
// };

function truncateString(value: string, maxLength: number) {
  if (value === null) return "No Data";
  const str = value.toString();
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
}

export default function FieldMapping(props: any) {
  const {
    mappingObject: mappingInput,
    updateIntegrationFunction,
    selectedRecord,
    module,
    sourceFieldsObject,
  } = props;
  const { dataObject = {} } = useContext(AppContext);
  const { customDatasets = [] } = dataObject ?? {};
  const [mappingObject, setMappingObject] = useState<{
    [key: string]: {
      sourceField: string;
      valueMapping: { [field: string]: string };
      bulkAssign: string | null;
      autoMap: string | null;
    };
  }>({});

  const [loading, setLoading] = useState(false);
  const [rulesProps, setRulesProps] = useState({
    sourceField: null,
    targetField: null,
  });
  const [rulesModalOpened, { open: openRulesModal, close: closeRulesModal }] =
    useDisclosure(false);

  const schema = useMemo(
    () => (module ? getDataSchema(module, customDatasets) : {}),
    [module, customDatasets]
  );
  const targetFields = useMemo(
    () =>
      Object.keys(schema).filter((fieldName: string) => {
        return (
          !schema[fieldName].excludeFromForm ||
          ["integrationSystemId", "createdAt", "updatedAt", "audit"].includes(
            fieldName
          )
        );
      }),
    [schema]
  );

  useEffect(() => {
    // if (mappingInput) {
    setMappingObject(mappingInput ?? {});
    // }
  }, [mappingInput]);

  const onMappingChange = (
    targetField: string,
    sourceField: string,
    bulkAssign: string | null = null,
    autoMap: string | null = null
  ) => {
    const mappingObjectCopy = mappingObject
      ? structuredClone(mappingObject)
      : {};

    if (!sourceField && !bulkAssign && !autoMap) {
      delete mappingObjectCopy[targetField];
    } else {
      mappingObjectCopy[targetField] = {
        sourceField,
        valueMapping: {},
        bulkAssign,
        autoMap,
      };
    }

    setMappingObject(mappingObjectCopy);
  };

  const onValueMappingUpdate = (
    targetField: string,
    valueMappingObject: any
  ) => {
    const mappingObjectCopy = structuredClone(mappingObject);
    mappingObjectCopy[targetField].valueMapping = valueMappingObject;

    setMappingObject(mappingObjectCopy);
    closeRulesModal();
  };

  const saveMappingObject = async () => {
    setLoading(true);
    const copy = structuredClone(selectedRecord);
    const parsedDataObject = JSON.parse(copy.dataObject);
    parsedDataObject.mappingObject = mappingObject;
    copy.dataObject = JSON.stringify(parsedDataObject);
    copy.status = "Updated Configuration";
    await updateIntegrationFunction(copy);
    setLoading(false);
  };

  const targetValues = useMemo(() => {
    if (!schema || !rulesProps.targetField) return [];
    const { type } = schema[rulesProps.targetField] ?? { type: "" };

    //@ts-ignore
    if (["boolean"].includes(type))
      return [
        { value: true, label: "True" },
        { value: false, label: "False" },
      ];

    const { dataSource, accessorFunction, list } =
      schema[rulesProps.targetField].formOptions.options ?? {};

    //@ts-ignore
    if (list)
      return list //@ts-ignore
        .filter((value: string) => value) //@ts-ignore
        .map((value: string) => ({ value, label: value }));
    if (
      dataSource &&
      dataObject &&
      accessorFunction &&
      dataObject[dataSource]
    ) {
      return accessorFunction(dataObject[dataSource]);
    }
    return [];
  }, [rulesProps]);

  const selectOptions = Object.keys(sourceFieldsObject)
    .map((field: string) => {
      const firstValue = sourceFieldsObject[field][0];
      if (firstValue) {
        return {
          value: field,
          label: `${field} (e.g. ${truncateString(firstValue, 35)})`,
        };
      }
      return { value: field, label: field };
    })
    .sort((a: any, b: any) => {
      return b.label === a.label ? 0 : b.label > a.label ? -1 : 1;
    });

  return (
    <CustomPaper mt={"md"}>
      <Title order={2} mb="md">
        Field Mapping
      </Title>
      <Box ml={"1em"} mr={"0.5em"} style={{ overflow: "scroll" }}>
        <Table ml={"1em"}>
          <thead>
            <tr>
              <th>Target Data Model Field</th>
              <th>Source Field</th>
              <th>Mapping Options</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {targetFields.map((targetField: any) => {
              const selectedSourceField = mappingObject
                ? //@ts-ignore
                  mappingObject[targetField]
                  ? //@ts-ignore
                    mappingObject[targetField].sourceField
                  : null
                : null;
              const bulkAssignValue = mappingObject
                ? //@ts-ignore
                  mappingObject[targetField]
                  ? //@ts-ignore
                    mappingObject[targetField].bulkAssign
                  : null
                : null;
              const autoMapValue = mappingObject
                ? //@ts-ignore
                  mappingObject[targetField]
                  ? //@ts-ignore
                    mappingObject[targetField].autoMap
                  : null
                : null;
              return (
                <FieldMappingRow
                  key={targetField}
                  sourceFieldsObject={sourceFieldsObject}
                  selectOptions={selectOptions}
                  selectedSourceField={selectedSourceField}
                  bulkAssignValue={bulkAssignValue}
                  autoMapValue={autoMapValue}
                  targetField={targetField}
                  type={schema[targetField].type}
                  targetFieldTitle={schema[targetField].title}
                  onMappingChange={onMappingChange}
                  openRulesModal={openRulesModal}
                  setRulesProps={setRulesProps}
                  schemaObject={schema[targetField]}
                  dataObject={dataObject}
                />
              );
            })}
          </tbody>
        </Table>
      </Box>
      <Group position="right" mt={"md"}>
        <Button
          onClick={saveMappingObject}
          loading={loading}
          disabled={
            !(
              mappingObject.integrationSystemId &&
              mappingObject.integrationSystemId.sourceField
            )
          }
        >
          Save Field Mappings
        </Button>
      </Group>
      <Modal
        title={"Value Mapping"}
        size={"xl"}
        opened={rulesModalOpened}
        onClose={closeRulesModal}
        zIndex={300}
      >
        <CreateRules
          rulesProps={rulesProps}
          targetValues={targetValues}
          sourceFieldsObject={sourceFieldsObject}
          value={[[], []]}
          onChange={() => null}
          transferAllMatchingFilter={true}
          onValueMappingUpdate={onValueMappingUpdate}
          initialMappingValues={
            mappingObject &&
            rulesProps.targetField &&
            mappingObject[rulesProps.targetField]
              ? //@ts-ignore
                mappingObject[rulesProps.targetField].valueMapping
              : {}
          }
        />
      </Modal>
    </CustomPaper>
  );
}
