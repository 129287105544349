import React from "react";
import { useContext, useMemo } from "react";
import { createFilterFunction } from "../../functions/createFilterFunction";
import { useElementSize } from "@mantine/hooks";
import AppContext from "../../../../context/AppContext";
import useLoadingStatus from "../../../../hooks/useLoadingStatus";
import { calculatePercentageOfDurationPassed } from "../../functions/dateFunctions";

export function useFinanceVisual({
  dashboardFilterObjectList,
  startDate,
  endDate,
}: any) {
  const { ref, width, height } = useElementSize();
  const { dataObject, currentProgrammes } = useContext(AppContext);
  const { projects = [] } = dataObject;

  const localFilterFunction = useMemo(
    () =>
      createFilterFunction(
        [dashboardFilterObjectList],
        "startDate",
        startDate,
        endDate
      ),
    [dashboardFilterObjectList, startDate, endDate]
  );

  const filteredProjects = useMemo(() => {
    return projects
      .filter(
        (project: any) =>
          project.period === "Live" &&
          currentProgrammes.includes(project.programmeProjectsId)
      )
      .filter(localFilterFunction);
  }, [projects, localFilterFunction, currentProgrammes]);

  const loading = useLoadingStatus(["projects"]);

  const projectObject = useMemo(
    () =>
      filteredProjects.reduce(
        (object: any, project: any) => {
          object.total += project.revenueSold;
          // object[project.programme.verticalProgrammesId].consumed +=
          //   project.consumed;
          // object[project.programme.verticalProgrammesId].paid +=
          //   project.revenueActual;

          object.consumed += project?.statements?.items?.reduce(
            (total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  // if (!invoice.paid) {
                  total += invoice.amount;
                  // }
                  return total;
                },
                0
              );
              return total;
            },
            0
          );

          object.paid += project?.statements?.items?.reduce(
            (total: any, statement: any) => {
              total += statement?.invoices?.items.reduce(
                (total: any, invoice: any) => {
                  if (invoice.paid) {
                    total += invoice.amount;
                  }
                  return total;
                },
                0
              );
              return total;
            },
            0
          );
          return object;
        },
        { total: 0, paid: 0, consumed: 0 }
      ),
    [filteredProjects]
  );

  //End new

  const paid = projectObject.paid;
  const consumed = projectObject.consumed;
  const total = projectObject.total;

  const surplusConsumed = Math.max(0, consumed - paid);
  const remainingValue = total - surplusConsumed - paid;

  const paidPercentage = (paid / total) * 100;
  const consumedSurplusPercentage = (surplusConsumed / total) * 100;

  const remainingPercentage = (remainingValue / total) * 100;

  return {
    total,
    paid,
    paidPercentage,
    surplusConsumed,
    consumedSurplusPercentage,
    remainingValue,
    remainingPercentage,
    loading,
    ref,
    filteredProjects,
  };
}
