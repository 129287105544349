import {
  createStyles,
  Text,
  Card,
  RingProgress,
  Group,
  useMantineColorScheme,
  // useMantineTheme,
  Skeleton,
  Progress,
  SimpleGrid,
  Stack,
  // LoadingOverlay,
} from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import { Storage } from "aws-amplify";
import { nFormatter } from "../../functions/formatFunctions";
import { IconCheck } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    cursor: "pointer",
    height: "100%",
    userSelect: "none",
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  selected: {
    boxShadow: `0px 0px 1px 1px ${theme.colors[theme.primaryColor][9]}`,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    // [theme.fn.smallerThan("xs")]: {
    //   flexDirection: "column",
    // },
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",

    [theme.fn.smallerThan("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
}));

interface StatsRingCardProps {
  name: string;
  loading: boolean;
  tenant: string;
  id: string;
  showTarget: boolean;
  target: number;
  totalsObject: any;
  setCurrentVertical: any;
  currentVertical: any;
  showRevenue: boolean;
  stats: {
    value: number;
    label: string;
  }[];
}

export function VerticalTile({
  name,
  tenant,
  id,
  showTarget,
  target,
  stats,
  totalsObject,
  loading,
  setCurrentVertical,
  currentVertical,
  showRevenue,
}: StatsRingCardProps) {
  const { classes, theme } = useStyles();
  const mode = useMantineColorScheme();

  const [logo, setLogo] = useState<string | null>(null);
  const [darkLogo, setDarkLogo] = useState<string | null>(null);

  const updateLogo = async (organisation: string | null) => {
    if (organisation) {
      const newLogo = await Storage.get(organisation + "_light");
      const newDarkLogo = await Storage.get(organisation + "_dark");
      setLogo(newLogo);
      setDarkLogo(newDarkLogo);
    }
  };

  useEffect(() => {
    updateLogo(`${tenant}_${id}`);
  }, []);

  const percentagePaid = useMemo(() => {
    if (!totalsObject || totalsObject.total === 0) return "-";
    return `${Math.round((totalsObject.paid * 100) / totalsObject.total)}%`;
  }, [totalsObject]);

  const items = useMemo(
    () =>
      stats.map((stat) => (
        <div key={stat.label}>
          {loading ? (
            <Skeleton height={16} />
          ) : (
            <Text className={classes.label}>{stat.value}</Text>
          )}
          <Text size="xs" color="dimmed">
            {stat.label}
          </Text>
        </div>
      )),
    [stats, loading, classes.label]
  );

  // const greenPercentage = !(!totalsObject || totalsObject.total === 0)
  //   ? Math.min(Math.round((totalsObject.paid * 100) / totalsObject.total), 100)
  //   : 0;

  // const orangePercentage = !(!totalsObject || totalsObject.total === 0)
  //   ? Math.max(
  //       Math.round(
  //         ((totalsObject.consumed - totalsObject.paid) * 100) /
  //           totalsObject.total
  //       ),
  //       0
  //     )
  //   : 0;

  const distanceFromTarget = target - (totalsObject ? totalsObject.total : 0);

  return (
    <Card
      withBorder
      p="xl"
      radius="md"
      className={`${classes.card} ${
        currentVertical === id ? classes.selected : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        if (id === "Total") return null;
        const newVertical = currentVertical === id ? "All" : id;
        setCurrentVertical(newVertical);
      }}
    >
      <div className={classes.inner}>
        <div>
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // overflow: "hidden",
              // minHeight: "60px",
              // maxHeight: "60px",
            }}
          >
            <Text className={classes.label}>{name}</Text>
            {/* <Image
              src={mode.colorScheme === "light" ? logo : darkLogo}
              style={{ maxWidth: "150px" }}
              withPlaceholder
              placeholder={<Text align="left">{name}</Text>}
            /> */}
          </div>
          <div>
            {loading ? (
              <Skeleton height={54} />
            ) : (
              <Text className={classes.lead} mt={30}>
                £{nFormatter(totalsObject ? totalsObject.total : 0, 1)}
              </Text>
            )}
            <Text color="dimmed">Total Value</Text>
          </div>
          {loading && <Skeleton height={54} />}
          {!loading && showTarget && (
            <>
              <Group position="right" mt="md" spacing={"xs"}>
                <Text size={"xs"}>Target</Text>
                <Text size={"md"} fw={700}>
                  £{nFormatter(target, 1)}
                </Text>
              </Group>
              <Progress
                value={Math.min(
                  ((totalsObject ? totalsObject.total : 0) * 100) / target,
                  100
                )}
              />
              <Group
                position="right"
                spacing={"xs"}
                style={{
                  color:
                    distanceFromTarget > 0
                      ? theme.colors.red[7]
                      : theme.colors.green[7],
                }}
              >
                <Text size={"sm"} fw={700}>
                  £
                  {nFormatter(
                    distanceFromTarget > 0
                      ? distanceFromTarget
                      : distanceFromTarget * -1,
                    1
                  )}
                </Text>
                <Text size={"xs"}>
                  {distanceFromTarget > 0 ? "below" : "above"} target
                </Text>
              </Group>
            </>
          )}
          <Group mt="lg">{items}</Group>
        </div>

        {showRevenue && (
          <div className={classes.ring}>
            {loading && <Skeleton circle height={150} />}
            {!loading && totalsObject && (
              <Stack spacing={0}>
                <RingProgress
                  roundCaps
                  thickness={10}
                  size={150}
                  sections={
                    !totalsObject || totalsObject.total === 0
                      ? [] //[{ value: 100, color: "red" }]
                      : [
                          {
                            value: totalsObject.paidPercentage,
                            color: "green", //theme.primaryColor,
                          },
                          {
                            value: totalsObject.consumedSurplusPercentage,
                            color: "yellow",
                          },
                          {
                            value: totalsObject.remainingPercentage,
                            color: "orange",
                          },
                          {
                            value: totalsObject.unclaimedPercentage,
                            color: "red",
                          },
                        ]
                  }
                  label={
                    <div>
                      <Text align="center" className={classes.label}>
                        {percentagePaid}
                      </Text>
                      <Text align="center" color="dimmed">
                        Revenue Paid
                      </Text>
                    </div>
                  }
                />
                <SimpleGrid cols={2} spacing={0}>
                  <Text size={"xs"} color="green">
                    Paid
                  </Text>
                  <Text size={"xs"} color="green">
                    £{nFormatter(totalsObject.paid, 1)}
                  </Text>
                  <Text size={"xs"} color="yellow">
                    Claimed
                  </Text>
                  <Text size={"xs"} color="yellow">
                    £{nFormatter(totalsObject.consumed, 1)}
                  </Text>
                  <Text size={"xs"} color="orange">
                    Forecast
                  </Text>
                  <Text size={"xs"} color="orange">
                    £{nFormatter(totalsObject.remainingValue, 1)}
                  </Text>
                  <Text size={"xs"} color="red">
                    Unclaimed
                  </Text>
                  <Text size={"xs"} color="red">
                    £{nFormatter(totalsObject.unclaimed, 1)}
                  </Text>
                </SimpleGrid>
              </Stack>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
