import { ISchema } from "../SchemaTypes";
import common from "./commonSchema";

const targetsSchema: ISchema = {
  vertical: {
    title: "Vertical",
    field: "vertical",
    // accessorFunction: (value: any) =>
    //   `${value.person.firstName} ${value.person.lastName}`,
    // formatFunction: (value: any) => value,
    formOptions: { required: false },
    excludeFromForm: true,
    excludeFromDataView: true,
    excludeFromChartBuilder: true,
    type: "belongsTo",
  },

  verticalTargetsId: {
    title: "Vertical",
    field: "verticalTargetsId",
    essentialKey: true,
    formOptions: {
      required: false,
      options: {
        dataSource: "verticals",
        addBlank: true,
        accessorFunction: (dataSource: any) =>
          (dataSource ?? [])
            .sort((a: any, b: any) => {
              return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
            })
            .map((row: any) => {
              return {
                value: row.id,
                label: row.name,
              };
            }),
      },
      order: 1,
    },
    type: "select",
  },

  module: {
    title: "Module",
    field: "module",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "Verticals",
      options: { list: ["Verticals"] },
    },
  },

  field: {
    title: "Field",
    field: "field",
    type: "select",
    formOptions: {
      required: false,
      defaultValue: "revenueSold",
      options: { list: ["revenueSold"] },
    },
  },

  value: {
    title: "Value",
    field: "value",
    type: "number",
    formOptions: { required: false },
  },

  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    formOptions: {
      required: false,
    },
  },

  ...common,
};

export default targetsSchema;
